import React, {Component} from 'react';
import {connect} from "react-redux";
import Slider from 'rc-slider';
import {translate} from 'react-translate';

import {renderMarks} from '../../../utils/configuration_utils';
import {fetchSensorConfig, saveSensorConfig} from "../../../routes/configuration/actions";
import {Loader} from '../../base_components';

class ConfigurationAlarmTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            recording_time_before: "",
            recording_time_after: "",
            alarm_duration_min: "",
            alarm_duration_max: ""
        };
        this.saveValues = this.saveValues.bind(this);
    }

    componentDidMount() {
        const {sensor_id} = this.props;
        const endpoint = `/sensor/${sensor_id}/alarm/`;
        this.props.fetchSensorConfig(sensor_id, endpoint);
    }

    revertToDefault(type) {
        const {default_values} = this.props.sensorConfiguration.config;
        switch (type) {
            case 'recording_time_before':
                this.setState({
                    recording_time_before: default_values.recording_time_before
                });
                break;
            case 'recording_time_after':
                this.setState({
                    recording_time_after: default_values.recording_time_after
                });
                break;
            case 'alarm_duration_min':
                this.setState({
                    alarm_duration_min: default_values.alarm_duration_min
                });
                break;
            case 'alarm_duration_max':
                this.setState({
                    alarm_duration_max: default_values.alarm_duration_max
                });
                break;
        }

    }

    onSliderChange(type, value) {
         switch (type) {
            case 'recording_time_before':
                this.setState({
                    recording_time_before: value
                });
                break;
            case 'recording_time_after':
                this.setState({
                    recording_time_after: value
                });
                break;
            case 'alarm_duration_min':
                this.setState({
                    alarm_duration_min: value
                });
                break;
            case 'alarm_duration_max':
                this.setState({
                    alarm_duration_max: value
                });
                break;
        }
    }

    isLoading() {
        const {sensorConfiguration} = this.props;
        return sensorConfiguration.loading;
    }

    saveValues() {
        const {sensor_id} = this.props;
        const endpoint = `/sensor/${sensor_id}/alarm/`;
        const data = this.state;
        this.props.saveSensorConfig(sensor_id, endpoint, data);
    }

    componentDidUpdate(prevProps) {
        // update values in slider after fetching configuration
        if (this.props.sensorConfiguration.config &&
            this.props.sensorConfiguration.config.current_values &&
            prevProps.sensorConfiguration.config != this.props.sensorConfiguration.config) {
                const {current_values} = this.props.sensorConfiguration.config;
                this.setState({
                    recording_time_before: current_values.recording_time_before,
                    recording_time_after: current_values.recording_time_after,
                    alarm_duration_min: current_values.alarm_duration_min,
                    alarm_duration_max: current_values.alarm_duration_max
                });
        }
    }

     render() {

        return (
            <div>
                <Loader loading={this.isLoading()}/>

                <div className="configTabTitle">
                    Configure alarm parameters
                </div>
                <div className="configTabSubtitle">
                    Far and near fields are referring to respectively the region of the image that is far and near the sensor.
                </div>

                <p className="configDividerTitle">Recording time</p>
                <hr className="configDivider"/>
                <p className="configDividerSubtitle">
                    The duration of the alarm video is (recording time before) + (recording time after) seconds long. The recording time before is the seconds of registration that are made before the alarm. The recording time after is the seconds of registration that are made after the alarm.
                </p>
                <div className="RecordingTime">
                    <div className="configSlider">
                        <p className="configNameAlarm">Recording time Before</p>
                        <Slider
                            min={10}
                            max={20}
                            marks={renderMarks(10, 20, 1)}
                            step={1}
                            value={this.state.recording_time_before}
                            onChange={ (value) => this.onSliderChange('recording_time_before', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('recording_time_before') }>
                            Revert to default
                        </button>
                    </div>

                    <div className="configSlider">
                        <p className="configNameAlarm">Recording time After</p>
                        <Slider
                            min={10}
                            max={20}
                            marks={renderMarks(10, 20, 1)}
                            step={1}
                            value={this.state.recording_time_after}
                            onChange={ (value) => this.onSliderChange('recording_time_after', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('recording_time_after') }>
                            Revert to default
                        </button>
                    </div>
                </div>

                <p className="configDividerTitle">Alarm Duration</p>
                <hr className="configDivider"/>
                <p className="configDividerSubtitle">
                    The duration of the alarm signal
                </p>
                <div className="AlarmDuration">
                    <div className="configSlider">
                        <p className="configNameAlarm">Alarm duration Min</p>
                        <Slider
                            min={2}
                            max={30}
                            marks={renderMarks(2, 30, 2)}
                            step={2}
                            value={this.state.alarm_duration_min}
                            onChange={ (value) => this.onSliderChange('alarm_duration_min', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('alarm_duration_min') }>
                            Revert to default
                        </button>
                    </div>

                    <div className="configSlider">
                        <p className="configNameAlarm">Alarm duration Max</p>
                        <Slider
                            min={20}
                            max={180}
                            marks={renderMarks(20, 180, 16)}
                            step={16}
                            value={this.state.alarm_duration_max}
                            onChange={ (value) => this.onSliderChange('alarm_duration_max', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('alarm_duration_max') }>
                            Revert to default
                        </button>
                    </div>
                </div>
                <div className="configSaveTab">
                    <button className="configSave" onClick={this.saveValues}>
                        Save
                    </button>
                </div>
            </div>
        );
    }
}


function mapStateToProps({sensorConfiguration}) {
    return {sensorConfiguration};
}

export default connect(mapStateToProps, {
    fetchSensorConfig, saveSensorConfig
})(translate('ConfigurationAlarmTab')(ConfigurationAlarmTab));