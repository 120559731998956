import React from 'react';

const Icon = props => {
    const {name, style, className, colored, white, bar, fill} = props;

    if (!name) return null;

    const bar_append = bar ? '_bar' : '';
    const color_append = white ? '_white' : colored ? '_color' : '';
    const fill_append = fill ? '_fill' : '';

    return (
        <img
            src={`/static/assets/icons/icon_${name}${bar_append}${color_append}${fill_append}.svg`}
            className={className}
            style={style}
        />
    );
};

export default Icon;