import axios from "axios";

import {buildActionObject, buildActionErrorObject, getApiPath, handleError} from "../../../utils/actions_utils";
import {
    SENSOR_FETCH_ERROR,
    SENSOR_FETCH_SUCCESS,
    SENSOR_LOADING,
    SENSOR_UPDATE_WS,
    SENSOR_WS_CLOSED,
} from "../../../values/action_types";
import {SENSOR_APP} from "../../../values/api_apps";
import {reqOptions} from "../../../utils/auth";
import {isFunction} from "../../../utils/utils";

const sendCommand = (url, params, onSuccess, onError, onEnd) => {
    // dispatch(buildActionObject(COMMAND_WAITING, null));

    const on_success = isFunction(onSuccess) ? onSuccess : () => {};
    const on_error = isFunction(onError) ? onError : () => {};
    const on_end = isFunction(onEnd) ? onEnd : () => {};

    return axios(getApiPath(SENSOR_APP, url), {
        ...reqOptions,
        params,
    })
        // .then(res => dispatch(buildActionObject(COMMAND_SUCCESS, res)))
        // .catch(error => handleError(error, dispatch, COMMAND_ERROR));
        .then(res => on_success(res))
        .catch(err => on_error(err))
        .finally(() => on_end());
};

export const sendPlantCommand = (plant_id, cmd, params, onSuccess, onError, onEnd) =>
    sendCommand(`/plant/${plant_id}/${cmd}/`, params, onSuccess, onError, onEnd);

export const sendSensorCommand = (sensor_id, cmd, params, onSuccess, onError, onEnd) =>
    sendCommand(`/sensor/${sensor_id}/${cmd}/`, params, onSuccess, onError, onEnd);


//Sensors command
export const fetchSensorStatus = sensor_id => dispatch => {
    if (!sensor_id) dispatch(buildActionErrorObject(SENSOR_FETCH_ERROR, {message: 'sensor_id must be specified'}));

    const url = getApiPath(SENSOR_APP, `/dashboard/sensor/${sensor_id}/`);

    dispatch(buildActionObject(SENSOR_LOADING));

    return axios.get(url, reqOptions)
        .then(res => dispatch(buildActionObject(SENSOR_FETCH_SUCCESS, {...res.data, id: sensor_id})))
        .catch(error => handleError(error, dispatch, SENSOR_FETCH_ERROR));
};

export const updateCurrentSensorWS = data => buildActionObject(SENSOR_UPDATE_WS, data);

export const sensorDetailWSClosed = () => buildActionObject(SENSOR_WS_CLOSED);