export default {
    ALARM: 'alarm',
    ALERT: 'alert',
    BATTERY: 'battery',
    LIGHT: 'light',
    LOAD_DOCUMENT: 'load_document',
    REFRESH: 'refresh',
    SENSOR: 'sensor',
    SIREN: 'siren',
    SLEEP: 'sleep',
    TRAFFIC_LIGHT: 'traffic_light',
    CONFIGURATION: 'configuration'
};