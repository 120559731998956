import en from './en';
import it from './it';
import da from './da';

const lan = {en, it, da};
const lang_code = process.env.ENV !== 'test'
    ? window.navigator.language.split('-')[0]
    : 'en';

export const DEFAULT_LANGUAGE = en;

export default lan[lang_code] || DEFAULT_LANGUAGE;