export const SensorStatusApi = {
    ONLINE: 'online',
    OFFLINE: 'spento',
    SLEEP: 'sleep',
    ALARM: 'allarme',
    DISABLED: 'disabled',
};

export const SensorStatus = {
    ONLINE: 'online',
    OFFLINE: 'offline',
    SLEEP: 'sleep',
    ALARM: 'alarm',
    DISABLED: 'disabled',
    ALARM_DISABLED: 'alarm_disabled',
};

export const BatteryStatusApi = {
    EMPTY: 'scarica',
    LOW: 'critica',
    MEDIUM: 'buona',
    FULL: 'carica',
    UNKNOWN: 'Unknown',
};

export const BatteryStatus = {
    EMPTY: 'empty',
    LOW: 'low',
    MEDIUM: 'medium',
    FULL: 'full',
    UNKNOWN: 'unknown',
};