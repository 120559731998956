import React from 'react';

import Popup from './popup';
import ErrorMessage from './error_message';

const ErrorMessagePopup = props => {
    const {idModal = 'error_popup_modal', text, onHide, show} = props;
    return (
        <Popup idModal={idModal} show={show} onHide={onHide}>
            <ErrorMessage text={text}/>
        </Popup>
    );
};

export default ErrorMessagePopup;