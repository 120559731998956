import React, {Component} from 'react';
import {connect} from "react-redux";
import {translate} from 'react-translate';

import ConfigSensor from "./config_sensor";
import {Grid} from "../base_components";

import colors from "../../values/colors";
import {getBorderText3} from "../../utils/utils";

class ConfigurationDashboard extends Component {

    renderTitle() {
        const {plant, t} = this.props;

        if (!plant.name) return null;

        return (
            <h1 className='page-title' style={_style.title}>{plant.name} {t("configuration")}</h1>
        );
    }

    render() {

        const {plant, showOneSensorPerRow} = this.props;

        return (
            <div>
                <div style={_style.container}>
                    {this.renderTitle()}
                </div>
                <div className='config-dashboard' style={_style.sensors}>
                    <Grid className='config-track-sensors'>
                        {plant.sensors.map(sensor =>
                            <ConfigSensor
                                sensor={sensor}
                                showOneSensorPerRow={showOneSensorPerRow}
                                key={sensor.id}
                            />
                        )}
                    </Grid>
                </div>
            </div>
        );
    }

}

export default connect()(translate('Menu')(ConfigurationDashboard));

const _style = {
    container: {
        margin: '2rem 0 0 0',
        padding: '.8rem 1rem',
        border: '1.5px solid',
        backgroundColor: colors.secondaryLight,
        borderColor: colors.secondaryDark,
        borderRadius: 16,
    },
    sensors: {
        margin: '1rem 0 0 0',
    },
    title: {
        display: 'block',
        textAlign: 'center',
        margin: '.5rem 0 1.5rem 0',
        color: colors.creamLight,
        textShadow: getBorderText3(colors.disabledDark),
    },
};
