import React, {Component} from 'react';
import axios from 'axios';
import {translate} from 'react-translate';

import {getApiPath} from "../../utils/actions_utils";
import * as api_apps from '../../values/api_apps';
import colors from '../../values/colors';
import {isFunction, log} from "../../utils/utils";
// import style
import '../../../static/css/filter_panel.css';
//

const radio_no_filter_id = 'no-filter-selected';

export const INITIAL_STATE = {
    sensors: [],
    plants: [],
    selectedSensors: [],
    selectedPlants: [],
    filters: {},
    // set no-filters initial radio checked
    radioCheckedID: radio_no_filter_id,
};

class FilterPanel extends Component {
    constructor(props) {
        super(props);

        this.input_sensor_filter_id = 'input-sensor-filter';
        this.input_plant_filter_id = 'input-plant-filter';
        this.radio_no_filter_id = radio_no_filter_id;
        this.radio_sensor_id = 'sensor-radio';
        this.radio_plant_id = 'plant-radio';
        this.radio_group_name = 'filter-radios';

        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this.fetchSensors();
        this.fetchPlants();
    }

    componentDidUpdate() {
        log('Component update, state:', this.state);
    }

    fetchSensors() {
        // fetch all sensors data. This data is used to fill the sensor multiple select
        const url = getApiPath(api_apps.SENSOR_APP, '/sensors/?page_size=100');

        axios.get(url, {withCredentials: true})
            .then(({data}) => this.setState({
                sensors: data.results
                    .filter(s => s.active)
                    .map(s => ({
                        id: s.id,
                        name: s.name
                    }))
            }));
    }

    fetchPlants() {
        // fetch all plants data. This data is used to fill the plant multiple select
        const url = getApiPath(api_apps.SENSOR_APP, '/plants/?page_size=100');

        axios.get(url, {withCredentials: true})
            .then(({data}) => this.setState({
                plants: data.results.map(p => ({
                    id: p.id,
                    name: p.name
                }))
            }));
    }

    renderSensorMultipleSelect() {
        const {sensors, selectedSensors} = this.state;

        return (
            <select multiple className="form-control" id={this.input_sensor_filter_id} value={selectedSensors}
                    onChange={e => this.onSensorSelectChange(e)}>
                {sensors.map(s => <option value={s.id} key={s.id}>{s.name}</option>)}
            </select>
        );
    }

    onSensorSelectChange(e) {
        this.onMultipleSelectChange(e, 'selectedSensors', 'id_sensor', this.radio_sensor_id);
    }

    renderPlantMultipleSelect() {
        const {plants, selectedPlants} = this.state;

        return (
            <select multiple className="form-control" id={this.input_plant_filter_id} value={selectedPlants}
                    onChange={e => this.onPlantSelectChange(e)}>
                {plants.map(p => <option value={p.id} key={p.id}>{p.name}</option>)}
            </select>
        );
    }

    onPlantSelectChange(e) {
        this.onMultipleSelectChange(e, 'selectedPlants', 'id_plant', this.radio_plant_id);
    }

    onMultipleSelectChange(event, state_prop, filter_prop, radio_id) {
        if (!event || !state_prop) return null;

        const {options} = event.target;
        let list = [];
        for (let i = 0; i < options.length; i++) {
            const {selected, value} = options[i];
            if (selected) list.push(parseInt(value));
        }

        this.setState({
            [state_prop]: list,
            filters: {
                [filter_prop]: list
            },
            radioCheckedID: radio_id
        });
    }

    onNoFiltersChange() {
        const radio = document.getElementById(this.radio_no_filter_id);
        if (radio && radio.checked) {
            this.setState({
                filters: {},
                radioCheckedID: this.radio_no_filter_id,
            });
        }
    }

    onRadioChange(htmlID) {
        const radio = document.getElementById(htmlID);

        if (radio && radio.checked) {
            let state_prop = null, filter_prop = null;
            if (htmlID === this.radio_sensor_id) {
                state_prop = 'selectedSensors';
                filter_prop = 'id_sensor';
            } else if (htmlID === this.radio_plant_id) {
                state_prop = 'selectedPlants';
                filter_prop = 'id_plant';
            }

            if (state_prop && filter_prop) {
                this.setState({
                    filters: {
                        [filter_prop]: this.state[state_prop]
                    },
                    radioCheckedID: htmlID
                });
            }
        }
    }

    onConfirmClick() {
        const {onConfirm} = this.props;
        if (isFunction(onConfirm)) onConfirm(this.state.filters);
    }

    resetFilters() {
        const {sensors, plants} = this.state;

        this.setState({
            ...INITIAL_STATE,
            sensors,
            plants
        });
    }

    render() {
        const {collapse, htmlID, t} = this.props;
        const className = collapse ? 'collapse' : '';

        return (
            <div className={className} id={htmlID}>
                <form className={'form'} style={_style.container}>
                    <div className='form-row'>
                        <div className='col'>
                            <div className='form-group'>
                                <div className='form-check'>
                                    <input className="form-check-input" type="radio" name={this.radio_group_name}
                                           id={this.radio_no_filter_id} onChange={() => this.onNoFiltersChange()}
                                           checked={this.state.radioCheckedID === this.radio_no_filter_id}/>
                                    <label className="form-check-label" htmlFor={this.radio_no_filter_id}>
                                        {t('no_filters')}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='col-sm-12 col-md-6'>
                            <div className='form-group'>
                                <div className='form-check'>
                                    <input className="form-check-input" type="radio" name={this.radio_group_name}
                                           checked={this.state.radioCheckedID === this.radio_sensor_id}
                                           id={this.radio_sensor_id}
                                           onChange={() => this.onRadioChange(this.radio_sensor_id)}/>
                                    <label className="form-check-label" htmlFor={this.radio_sensor_id}>
                                        {t('sensor')}
                                    </label>
                                </div>
                                {this.renderSensorMultipleSelect()}
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <div className='form-group'>
                                <div className='form-check'>
                                    <input className="form-check-input" type="radio" name={this.radio_group_name}
                                           checked={this.state.radioCheckedID === this.radio_plant_id}
                                           id={this.radio_plant_id}
                                           onChange={() => this.onRadioChange(this.radio_plant_id)}/>
                                    <label className="form-check-label" htmlFor={this.radio_plant_id}>
                                        {t('plant')}
                                    </label>
                                </div>
                                {this.renderPlantMultipleSelect()}
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='col'>
                            <div className='float-right panel-actions'>
                                <button className='btn btn-danger' type='button'
                                        onClick={() => this.resetFilters()}>{t('reset')}
                                </button>
                                <button className='btn btn-success' type='button'
                                        onClick={() => this.onConfirmClick()}>{t('confirm')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default translate('FilterPanel')(FilterPanel);

const _style = {
    container: {
        borderRadius: 16,
        padding: '.8rem 1rem',
        border: '1.5px solid',
        borderColor: colors.disabledDark,
        backgroundColor: colors.disabledLight,
        marginBottom: '1rem',
    }
};
