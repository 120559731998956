import React, {Component} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from "react-redux";

import urls from '../../../values/urls';

import {setNextLink} from "./actions";

class LoggedInRoute extends Component {
    constructor(props) {
        super(props);

        // console.log('redirect constructor');
        // console.log(window.location.toString());
        this.setNext();
    }

    setNext() {
        const {user} = this.props;

        if (!user.isAuthenticated) {
            const url = window.location.pathname + window.location.search;
            this.props.setNextLink(url);
        }
    }

    render() {
        // console.log('redirect rendering');
        const {component: Component, exact, user, ...rest} = this.props;

        return (
            <Route exact={exact} {...rest} render={(props) => (
                user.isAuthenticated
                    ? <Component {...props} />
                    : <Redirect to={urls.LOGIN}/>
            )}/>
        );
    }
}

function mapStateToProps({user}) {
    return {user};
}

export default connect(mapStateToProps, {setNextLink})(LoggedInRoute);