import React, {Component} from 'react';
import axios from "axios";

import {BaseContainerPage, Loader} from "../../base_components";

export default class TestPage extends Component {
    constructor(props) {
        super(props);

        this.state = {loading: false};
    }

    renderTestButton(onClick, text) {
        return (
            <button className='btn btn-info' onClick={onClick}>{text}</button>
        );
    }

    getReq(url) {
        this.setState({loading: true});

        axios.get(url, {withCredentials: true})
            .then(res => console.log(res.data))
            .catch(err => console.log(err))
            .finally(() => this.setState({loading: false}));
    }

    postReq(url) {
        this.setState({loading: true});

        axios.post(url, {}, {withCredentials: true})
            .then(res => console.log(res))
            .catch(err => console.log(err))
            .finally(() => this.setState({loading: false}));
    }

    onWhoAmIClick() {
        this.getReq('http://localhost:8000/users/api/v1/who-am-i/');
    }

    onVerifyTokenClick() {
        this.postReq('http://localhost:8000/users/api/v1/verify-token/');
    }

    onRefreshTokenClick() {
        this.postReq('http://localhost:8000/users/api/v1/refresh-token/');
    }

    onLogoutClick() {
        this.postReq('http://localhost:8000/users/api/v1/logout/');
    }

    render() {
        return (
            <BaseContainerPage>
                <Loader loading={this.state.loading}/>

                <h1 className='page-title'>Test</h1>

                <p>Controlla i risultati nella console del browser.</p>

                <ul>
                    <li style={_style.li}>{this.renderTestButton(() => this.onWhoAmIClick(), 'Who am I')}</li>
                    <li style={_style.li}>{this.renderTestButton(() => this.onVerifyTokenClick(), 'Verify token')}</li>
                    <li style={_style.li}>{this.renderTestButton(() => this.onRefreshTokenClick(), 'Refresh token')}</li>
                    <li style={_style.li}>{this.renderTestButton(() => this.onLogoutClick(), 'Logout')}</li>
                </ul>
            </BaseContainerPage>
        );
    }
}

const _style = {
    li: {
        marginBottom: '1rem',
    },
};