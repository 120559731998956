import {command_list as Commands, types as CommandTypes, command_value as Values} from "../values/commands";
import {isObject} from "./utils";
import {getSensorStatus} from "./sensor_utils";
import {SensorStatus} from "../values/statuses";

export const getIconNameByType = type => {
    // "icon_" prefix is already provided by <Icon/> component
    let icon_name;

    switch (type) {
        case CommandTypes.RESTART:
            icon_name = 'refresh';
            break;
        case CommandTypes.IDLE:
            icon_name = 'sensor';
            break;
        case CommandTypes.LIGHT:
            icon_name = 'light';
            break;
        case CommandTypes.SIREN:
            icon_name = 'siren';
            break;
        case CommandTypes.CAMERA:
            icon_name = 'camera';
            break;
        default:
            icon_name = '';
            break;
    }

    return icon_name;
};

export const getCommandByType = type => {
    switch (type) {
        case CommandTypes.RESTART:
            return Commands.RESTART;
        case CommandTypes.IDLE:
            return Commands.IDLE;
        case CommandTypes.LIGHT:
            return Commands.LIGHT;
        case CommandTypes.SIREN:
            return Commands.SIREN;
        case CommandTypes.SENSOR_SWITCH:
            return Commands.SENSOR_SWITCH_MODE;
        case CommandTypes.PLANT_MODE:
            return Commands.PLANT_MODE;
        default:
            return '';
    }
};

/**
 *
 * @param plant
 * @returns {object}
 */
export const getPlantInitialCheckedObject = plant => {
    if (!isObject(plant)) throw new TypeError('"plant" must be a not null or undefined object');

    const initial = {
        [CommandTypes.LIGHT]: false,
        [CommandTypes.IDLE]: true,
        [CommandTypes.SIREN]: false,
    };

    if (!plant.sensors || !plant.sensors.length) return initial;

    initial[CommandTypes.IDLE] = false;

    return plant.sensors
        .map(s => getSensorInitialCheckedObject(s))
        .reduce((total, curr) => ({
            [CommandTypes.LIGHT]: total[CommandTypes.LIGHT] || curr[CommandTypes.LIGHT],
            [CommandTypes.IDLE]: total[CommandTypes.IDLE] || curr[CommandTypes.IDLE],
            [CommandTypes.SIREN]: total[CommandTypes.SIREN] || curr[CommandTypes.SIREN],
        }), initial);

    /*
    const semaphore = isPlantSomePropTurnedOn(plant, 'web_sem', false,
            s => getSensorStatus(s) === SensorStatus.ALARM);
    const idle = isPlantSomePropTurnedOn(plant, 'idle', true);
    const siren = isPlantSomePropTurnedOn(plant, 'web_sir', false,
            s => getSensorStatus(s) === SensorStatus.ALARM);

    return {
        [CommandTypes.LIGHT]: !!semaphore,
        [CommandTypes.IDLE]: !!idle,
        [CommandTypes.SIREN]: !!siren,
    };

     */
};

/**
 *
 * @param sensor
 * @returns {{[p: string]: boolean}}
 */
export const getSensorInitialCheckedObject = sensor => {
    if (!isObject(sensor)) throw new TypeError('"sensor" must be a not null or undefined object');

    let {idle, web_sem, web_sir} = (sensor.last_status || {});

    if (web_sem && web_sem == Values.UNKNOWN) {
        web_sem = Values.OFF;
    }

    if (web_sir && web_sir == Values.UNKNOWN) {
        web_sir = Values.OFF;
    }

    const statusVerbose = getSensorStatus(sensor);

    if (statusVerbose === SensorStatus.OFFLINE) {
        idle = true;
        web_sem = false;
        web_sir = false;
    }

    return {
        [CommandTypes.LIGHT]: !!web_sem,
        [CommandTypes.IDLE]: !!idle,
        [CommandTypes.SIREN]: !!web_sir
    };
};
