import React from 'react';
import _ from 'lodash';

import {getColorByStatus} from "../../utils/sensor_utils";
import {SensorStatus} from "../../values/statuses";

const dims = {
    HUGE: 'huge',
    BIG: 'big',
    MEDIUM: 'medium', // default
    SMALL: 'small',
};

const BASE_CLASS = 'status-badge';

const StatusBadge = props => {
    const {dimension = dims.MEDIUM, status, className, style} = props;

    const style_obj = {
        ..._style.main,
        backgroundColor: getColorByStatus(status),
        ...style,
    };

    const blink_class = status === SensorStatus.ALARM ? 'blink' : '';
    const dimension_class = _.values(dims).includes(dimension) ? `${BASE_CLASS}-${dimension}` : BASE_CLASS;

    return (
        <span className={`badge badge-danger ${blink_class} ${BASE_CLASS} ${dimension_class} ${className}`}
              style={style_obj}>{status}</span>
    );
};

const _style = {
    container: {
        display: 'inline',
        margin: 0,
    },
    main: {
        color: '#fff',
        lineHeight: 1.1,
    }
};

export default StatusBadge;