import urls from "../values/urls";

export const IS_ROUTER_ENV = process.env.BUILD_ENV === 'router';
export const IS_DEV_MODE = process.env.NODE_ENV === 'development';

export const areEqualObjects = (obj1, obj2) => {
    // console.log('areEqualObjects called');

    // both null or undefined, or comparable variables
    if (obj1 === obj2) return true;

    // one of them is null or undefined while the other is not
    if (!obj1 || !obj2) return false;

    // non-objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

    const obj1_k = Object.keys(obj1);
    const obj2_k = Object.keys(obj2);

    // different number of keys
    if (obj1_k.length !== obj2_k.length) return false;

    const key_length = obj1_k.length;
    let key_present = true;
    let i = 0;
    while (key_present && i < key_length) key_present &= obj1_k.includes(obj2_k[i++]);

    // all the keys of an object are not present in the other
    if (!key_present) return false;

    i = 0;
    let value_equal = true;
    while (value_equal && i < key_length) {
        const key = obj1_k[i++];
        const v1 = obj1[key];
        const v2 = obj2[key];

        value_equal = value_equal && (areEqualArrays(v1, v2) || areEqualObjects(v1, v2));
    }

    // check all values
    return value_equal;
};

export const areEqualArrays = (arr1, arr2) => {
    // console.log('areEqualArrays called');

    // both null or undefined, or comparable variables
    if (arr1 === arr2) return true;

    // one of them is an array while the other is not
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;

    // different length
    if (arr1.length !== arr2.length) return false;

    const arr_length = arr1.length;
    let value_equal = true;
    let i = 0;
    while (value_equal && i < arr_length) {
        const v1 = arr1[i];
        const v2 = arr2[i++];

        value_equal = value_equal && (areEqualArrays(v1, v2) || areEqualObjects(v1, v2));
    }

    // check values
    return value_equal;
};

export const getErrorPropString = error => {
    if (!error) return '';

    let prop = 'ERROR';

    if (error.response) prop += '_' + error.response.status;

    return prop;
};

export const isObject = obj => {
    return obj !== null
        && obj !== undefined
        && typeof obj === 'object'
        && !Array.isArray(obj);
};

export const isFunction = fun => typeof fun === 'function';

export const runFunction = fun => {
    if (fun && isFunction(fun)) fun();
};

export function log(msg = '', arg1) {
    const ENV = process.env.ENV;
    if (ENV === 'development' || ENV === 'staging') {
        if (!!arg1) console.log(msg, arg1);
        else console.log(msg);
    }
}

export const getBorderText2 = (color = '#000') => {
    return `2px 0 0 ${color}, 1.35px 1.35px 0 ${color}, 0 2px 0 ${color}, -1.35px 1.35px 0 ${color}, ` +
            `-2px 0 0 ${color}, -1.35px -1.35px 0 ${color}, 0 -2px 0 ${color}, 1.35px -1.35px 0 ${color}`;
};

export const getBorderText3 = (color = '#000') => {
    return `3px -2px 0 ${color}, 3px -1px 0 ${color}, 3px 0 0 ${color}, 3px 1px 0 ${color}, 3px 2px 0 ${color}, ` +
            `2px 3px 0 ${color}, 1px 3px 0 ${color}, 0 3px 0 ${color}, -1px 0 0 ${color}, -2px 3px 0 ${color}, ` +
            `-3px 2px 0 ${color}, -3px 1px 0 ${color}, -3px 0 0 ${color}, -3px -1px 0 ${color}, -3px -2px 0 ${color}, ` +
            `-2px -3px 0 ${color}, -1px -3px 0 ${color}, 0 -3px 0 ${color}, 1px 0 0 ${color}, 2px -3px 0 ${color}`;
};

export function naturalCompare(a, b, propName) {
    if(!a || !b) return null;

    if (propName) {
        a = a[propName];
        b = b[propName];
    }

    let ax = [], bx = [];

    a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]); });
    b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]); });

    while(ax.length && bx.length) {
        let an = ax.shift();
        let bn = bx.shift();
        let nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }

    return ax.length - bx.length;
}

export const getWebSocketURL = (api_app, uri) => {
    if (!api_app || !uri) return null;

    const version = 'v1';
    uri = uri.toString();
    uri = uri.startsWith('/') ? uri : `/${uri}`;
    return `${urls.WS_BASE}/${api_app}/ws/${version}${uri}`;
};
