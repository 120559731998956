import React from 'react';
import {Link} from "react-router-dom";

import colors from "../../../values/colors";

const DEFAULT_IMG = {
    url: '',
    alt: 'Immagine non disponibile',
};

const DEFAULT_TEXT = {
    text: '',
    href: null,
};

const renderLinkText = (text_obj, className, style) => {
    if (!text_obj || !text_obj.text) return null;

    const title_obj = <h1 className={className} style={style}>{text_obj.text}</h1>;

    if (!text_obj.href) return title_obj;

    return (
        <Link to={text_obj.href}>{title_obj}</Link>
    );
};

export default props => {
    const {
        img = DEFAULT_IMG, title = DEFAULT_TEXT, subtitle = DEFAULT_TEXT, text, idModal, onClick, children,
        style, className
    } = props;

    const modal_props = idModal
        ? {
            'data-toggle': 'modal',
            'data-target': `#${idModal}`,
        }
        : null;

    return (
        <div className={`post ${className || ''}`} onClick={onClick}
             style={{..._style.post, ...style}} {...modal_props}>
            <img src={img.url} alt={img.alt} style={_style.img}/>
            <div className='text-block' style={_style.textBlock}>
                {renderLinkText(title, 'title', _style.title)}
                {renderLinkText(subtitle, 'subtitle', _style.subtitle)}
                {text ? <p>{text}</p> : null}
            </div>

            {children}
        </div>
    );
};

const _style = {
    post: {
        display: 'flex',
        // flexDirection: see style.css,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '1rem',
        padding: '1rem',
        border: '1.5px solid',
        borderColor: colors.disabled,
        backgroundColor: colors.disabledTransparent,
        // boxShadow: '2px 3px 10px 1px #ccc',
        borderRadius: 8,
    },
    img: {
        display: 'inline-block',
        cursor: 'pointer',
        // width: see style.css,
    },
    textBlock: {
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // padding: see style.css,
    },
    title: {
        // fontSize: see style.css,
        color: '#000',
    },
    subtitle: {
        // fontSize: see style.css,
        color: colors.disabled,
    },
    modalImg: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
};