import lsvars from '../values/local_storage_vars';

export function getLocalStorageCurrentUser() {
    const ls_user = JSON.parse(localStorage.getItem(lsvars.USER.id));

    if (!ls_user) return null;

    return {...ls_user};
}

export function setLocalStorageCurrentUser(user_obj) {
    localStorage.setItem(lsvars.USER.id, JSON.stringify(user_obj));
}

export function removeLocalStorageCurrentUser() {
    localStorage.removeItem(lsvars.USER.id);
}

// TODO check if used?
//export function getAuthHeader() {
//    // return authorization header with jwt token
//    let user = getLocalStorageCurrentUser();
//
//    if (user && user.isAuthenticated && user.user.token) {
//        return {'Authorization': 'Bearer ' + user.user.token};
//    } else {
//        return {};
//    }
//}

export const reqOptions = {withCredentials: true};