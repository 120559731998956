import _BatteryIcon from './battery_icon';
import _CookieBanner from './cookie_banner';
import _FilterPanel from './filter_panel';
import _Menu from './menu';
import _StatusBadge from './status_badge';
import _VideoPlayerModal from './video_player_modal';
import _LoggedInRoute from './logged_in_route';
import _Notification from './notification';
import _PushNotification from './notification_push';
import _AlarmsCarousel from './alarms_carousel';
import _Command from './command';
import _CommandList from './command_list';
import _PlantDashboard from './dashboard_plant';
import _SensorsDashboard from './dashboard_sensors';
import _SensorPreview from './sensor_preview';
import _SensorList from './sensor_list';
import _EditRuleModal from './rule_edit_modal';
import _NewRuleModal from './rule_new_modal';
import _RulePreview from './rule_preview';
import _NotificationSettingsModal from './settings_notification_modal';
import _MenuItem from './menu_item';
import _SensorPreviewIcon from './sensor_preview_icon';
import _SemaphoreIcon from './semaphore_icon';
import _SirenIcon from './siren_icon';
import _PlantSelect from './plant_select';
import _ConfigurationDashboard from "./config_dashboard";
import _ConfigurationSensitivityTab from "./config_sensor_sensitivity";
import _ConfigurationDetectionTab from "./config_sensor_detection";
import _ConfigurationAlarmTab from "./config_sensor_alarm";

export const BatteryIcon = _BatteryIcon;
export const CookieBanner = _CookieBanner;
export const FilterPanel = _FilterPanel;
export const Menu = _Menu;
export const StatusBadge = _StatusBadge;
export const VideoPlayerModal = _VideoPlayerModal;
export const LoggedInRoute = _LoggedInRoute;
export const Notification = _Notification;
export const PushNotification = _PushNotification;
export const AlarmsCarousel = _AlarmsCarousel;
export const Command = _Command;
export const CommandList = _CommandList;
export const PlantDashboard = _PlantDashboard;
export const SensorsDashboard = _SensorsDashboard;
export const SensorPreview = _SensorPreview;
export const SensorList = _SensorList;
export const EditRuleModal = _EditRuleModal;
export const NewRuleModal = _NewRuleModal;
export const RulePreview = _RulePreview;
export const NotificationSettingsModal = _NotificationSettingsModal;
export const MenuItem = _MenuItem;
export const SensorPreviewIcon = _SensorPreviewIcon;
export const SemaphoreIcon = _SemaphoreIcon;
export const SirenIcon = _SirenIcon;
export const PlantSelect = _PlantSelect;
export const ConfigurationDashboard = _ConfigurationDashboard;
export const ConfigurationSensitivityTab = _ConfigurationSensitivityTab;
export const ConfigurationDetectionTab = _ConfigurationDetectionTab;
export const ConfigurationAlarmTab = _ConfigurationAlarmTab;

export default {
    BatteryIcon,
    CookieBanner,
    FilterPanel,
    Menu,
    StatusBadge,
    VideoPlayerModal,
    LoggedInRoute,
    Notification,
    PushNotification,
    AlarmsCarousel,
    Command,
    CommandList,
    PlantDashboard,
    SensorsDashboard,
    SensorPreview,
    SensorList,
    EditRuleModal,
    NewRuleModal,
    RulePreview,
    NotificationSettingsModal,
    MenuItem,
    PlantSelect,
    SensorPreviewIcon,
    SemaphoreIcon,
    SirenIcon,
    ConfigurationDashboard,
    ConfigurationSensitivityTab,
    ConfigurationDetectionTab,
    ConfigurationAlarmTab,
};