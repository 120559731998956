import React, {Component} from 'react';
import {connect} from "react-redux";

import CommandList from "./command_list";
import SensorList from "./sensor_list";

import {isObject} from "../../utils/utils";
import {getPlantInitialCheckedObject} from "../../utils/commands_utils";
import {buildActionObject} from "../../utils/actions_utils";
import {PLANT_FIRST_SENSOR_ALARM, PLANT_SECOND_SENSOR_LIGHT} from "../../values/action_types";

class DashboardPlant extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let plant = this.props.plant;
        if (!isObject(plant)) throw new EvalError('"plant" is required and must be an object');

        const {id, name} = plant;
        const initialChecked = getPlantInitialCheckedObject(plant);

        return (
            <div className='dashboard-track' style={_style.container}>

                {
                    process.env.SENTRY_ENV === 'staging'
                        ? <>
                            <button
                                type={'button'}
                                onClick={() => dispatch(buildActionObject(PLANT_FIRST_SENSOR_ALARM))}  // eslint-disable-line no-undef
                            >
                                First sensor alarm
                            </button>

                            <button
                                type={'button'}
                                onClick={() => dispatch(buildActionObject(PLANT_SECOND_SENSOR_LIGHT, true))}  // eslint-disable-line no-undef
                            >
                                Turn on second sensor&#39;s light
                            </button>

                            <button
                                type={'button'}
                                onClick={() => dispatch(buildActionObject(PLANT_SECOND_SENSOR_LIGHT, false))}  // eslint-disable-line no-undef
                            >
                                Turn off second sensor&#39;s light
                            </button>
                        </>
                        : null
                }

                <CommandList idPlant={id} title={name} initialChecked={initialChecked}/>
                <SensorList track={plant} showOneSensorPerRow={this.props.showOneSensorPerRow}/>
            </div>
        );
    }
}

export default connect()(DashboardPlant);

const _style = {
    container: {
        margin: '1.8rem 0 0 0',
    },
};
