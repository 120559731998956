import colors from './colors';

export const errorMessageStyle = {
    lineHeight: 1,
    padding: '.5rem',
    borderRadius: 5,
    border: '1.5px solid',
    borderColor: colors.error,
    backgroundColor: colors.errorLight,
    color: colors.errorDark,
};