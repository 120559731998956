import React, {Component} from 'react';
import {connect} from "react-redux";
import {translate} from 'react-translate';

import {updateNotificationsRules} from "./rule_edit_modal/actions";
import {Loader, Modal} from "../base_components";
import RulePreview from './rule_preview';
import EditRuleModal from "./rule_edit_modal";
import NewRuleModal from "./rule_new_modal";

class NotificationSettingsModal extends Component {
    constructor(props) {
        super(props);

        this.default_id = 'settings-notification';
        this.edit_rule_modal_id = 'edit_rule_modal';
        this.new_rule_modal_id = 'new_rule_modal';

        this.state = {
            mod: {},
            opacity: 1,
            loading: false,
            show: props.show,
            show_rule_edit_modal: false,
            show_rule_new_modal: false,
            id_mod_rule: -1,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({show: this.props.show});
        }
    }

    getModRuleByState() {
        const {id_mod_rule} = this.state;
        const {rules} = this.props.notifications;

        if (!rules || !rules.results || !id_mod_rule || !id_mod_rule < 0) return null;

        return rules.results.find(r => r.id === id_mod_rule);
    }

    getModalId() {
        const {idHtml} = this.props;
        return idHtml || this.default_id;
    }

    onSave() {
        const {mod} = this.state;
        if (mod && Object.keys(mod).length) {
            this.setState({loading: true});

            this.props.updateNotificationsRules(Object.values(mod), () => {
                this.dismissModal();
            });
        }
    }

    onCancel() {
        this.dismissModal({mod: {}});
    }

    dismissModal(stateProps) {
        this.setState({
            loading: false,
            show: false,
            ...(stateProps || {}),
        });
    }

    renderRules() {
        const {props, state} = this;
        const {t} = props;
        const {rules, time_filters} = props.notifications;

        if (!rules || !time_filters) return null;
        if (rules.results && !rules.results.length) return <p>{t('no_rules')}</p>;
        if (rules.error || time_filters.error) return <p>{t('rules_error')}</p>;

        const {mod} = state;

        const onActivateClick = rule => this.setState({
            mod: {
                ...mod,
                [rule.id]: {
                    ...rule,
                    active: true,
                },
            }
        });

        const onDeactivateClick = rule => this.setState({
            mod: {
                ...mod,
                [rule.id]: {
                    ...rule,
                    active: false,
                },
            }
        });

        return rules.results.map(rule =>
            <RulePreview
                key={rule.id}
                rule={mod[rule.id] || rule}
                timeFilters={time_filters}
                onEditClick={() => this.setState({
                    show_rule_edit_modal: true,
                    id_mod_rule: rule.id,
                })}
                onActivateClick={() => onActivateClick(rule)}
                onDeactivateClick={() => onDeactivateClick(rule)}
            />
        );
    }

    render() {
        const id = this.getModalId();
        const {show, loading, opacity, show_rule_edit_modal, show_rule_new_modal} = this.state;
        const {notifications, plants, events, onShow, onShown, onHide, onDismiss, t} = this.props;

        return (
            <div>
                <Modal id={id} show={show} onShow={onShow} onShown={onShown} onHide={onHide} onDismiss={onDismiss}
                       style={{opacity}}>
                    <Loader loading={loading}/>

                    <div className='modal-body'>
                        <p className='modal-title'>{t('title')}</p>
                        <p className='modal-controls'>
                            <button className='btn btn-info'
                                    onClick={() => this.setState({show_rule_new_modal: true})}>{t('new_rule_btn')}
                            </button>
                        </p>

                        {this.renderRules()}
                    </div>
                    <div className='modal-footer'>
                        <button className='btn btn-secondary' onClick={() => this.onCancel()}>{t('cancel_btn')}</button>
                        <button className='btn btn-primary' onClick={() => this.onSave()}>{t('save_btn')}</button>
                    </div>
                </Modal>

                {/* Edit rule modal */}
                <EditRuleModal
                    idModal={this.edit_rule_modal_id}
                    rule={this.getModRuleByState()}
                    show={show_rule_edit_modal}
                    timeFilters={notifications.time_filters}
                    plants={plants}
                    eventList={events}
                    onShow={() => this.setState({opacity: 0})}
                    onDismiss={() => this.setState({opacity: 1})}
                    onHide={() => this.setState({show_rule_edit_modal: false})}
                />

                {/* New rule modal */}
                <NewRuleModal
                    idModal={this.new_rule_modal_id}
                    rule={{}}
                    show={show_rule_new_modal}
                    timeFilters={notifications.time_filters}
                    plants={plants}
                    eventList={events}
                    onShow={() => this.setState({opacity: 0})}
                    onDismiss={() => this.setState({opacity: 1})}
                    onHide={() => this.setState({show_rule_new_modal: false})}
                />
            </div>
        );
    }
}

const mapStateToProps = ({settings}) => {
    const {notifications, plants, events} = settings;
    return {notifications, plants, events};
};

export default connect(mapStateToProps, {
    updateNotificationsRules
})(translate('NotificationSettingsModal')(NotificationSettingsModal));