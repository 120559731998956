import _404 from './pages/404';
import _TestPage from './pages/test';
import _SentryErrorGeneratorPage from './pages/sentry';

export const NotFoundPage = _404;
export const TestPage = _TestPage;
export const SentryErrorGeneratorPage = _SentryErrorGeneratorPage;

export default {
    NotFoundPage,
    TestPage,
    SentryErrorGeneratorPage
};