import _BaseContainerPage from './components/base_container_page';
import _ErrorMessage from './components/error_message';
import _ErrorMessagePopup from './components/error_message_popup';
import _Grid from './components/grid';
import _GridElement from './components/grid_element';
import _Icon from './components/icon';
import _Loader from './components/loader';
import _Modal from './components/modal';
import _Popup from './components/popup';
import _Post from './components/post';
import _PostList from './components/post_list';
import _ConfirmModal from './components/confirm_modal';
import _ErrorBoundary from './components/error_boundary';

export const BaseContainerPage = _BaseContainerPage;
export const ErrorMessage = _ErrorMessage;
export const ErrorMessagePopup = _ErrorMessagePopup;
export const Grid = _Grid;
export const GridElement = _GridElement;
export const Icon = _Icon;
export const Loader = _Loader;
export const Modal = _Modal;
export const Popup = _Popup;
export const Post = _Post;
export const PostList = _PostList;
export const ConfirmModal = _ConfirmModal;
export const ErrorBoundary = _ErrorBoundary;

export default {
    BaseContainerPage,
    ConfirmModal,
    ErrorMessage,
    ErrorMessagePopup,
    Grid,
    GridElement,
    Icon,
    Loader,
    Modal,
    Popup,
    Post,
    PostList,
    ErrorBoundary
};
