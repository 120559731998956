import React from 'react';
import {translate} from 'react-translate';

import {getTimeFilterShortDescription} from "../../utils/settings_utils";
import colors from '../../values/colors';

const voidFunc = () => {};

const RulePreview = props => {
    const {rule, timeFilters, onEditClick, onActivateClick, onDeactivateClick, t} = props;

    if (!rule || !timeFilters) return null;

    const {sensors, active, name, time_filters} = rule;

    const onEdit = onEditClick || voidFunc;
    const onActivate = onActivateClick || voidFunc;
    const onDeactivate = onDeactivateClick || voidFunc;
    const onChangeActivate = active ? onDeactivate : onActivate;

    return (
        <div className='rule-preview'
             style={{..._style.ruleListItem, ...(active ? _style.activeRule : _style.notActiveRule)}}>
            <p style={{..._style.ruleLabel, ...(active ? _style.ruleLabelActive : _style.ruleLabelNotActive)}}>
                {
                    active
                        ? t('active_rule_label', {n: sensors.length})
                        : t('not_active_rule_label')
                }
            </p>

            <p style={_style.titleItem}>{name}</p>

            {
                time_filters.map(tf_id => {
                        const tf = timeFilters.results.find(t => t.id === tf_id);
                        return <p style={_style.subtitleItem} className='text-muted'
                                  key={tf_id}>{getTimeFilterShortDescription(tf)}</p>;
                    }
                )
            }

            <div className='rule-footer'>
                <button type='button' className='btn btn-dark' onClick={() => onEdit()}>{t('edit_btn')}</button>
                <button type='button' className='btn btn-dark float-right' onClick={() => onChangeActivate()}>
                    {active ? t('deactivate_btn') : t('activate_btn')}</button>
            </div>
        </div>
    );
};

export default translate('RulePreview')(RulePreview);

const _style = {
    titleItem: {
        color: '#222',
        fontSize: '1.1rem',
        margin: '.2rem 0',
    },
    subtitleItem: {
        color: '#aaa',
        fontSize: '.8rem',
        margin: '.2rem 0',
    },
    ruleListItem: {
        borderRadius: 5,
        padding: '.5rem',
        marginBottom: '.75rem',
    },
    activeRule: {
        border: `2px solid ${colors.success}`,
        backgroundColor: colors.successLight,
    },
    notActiveRule: {
        border: `2px solid ${colors.disabled}`,
        backgroundColor: colors.disabledLight,
    },
    ruleLabel: {
        fontSize: '.8rem',
        fontWeight: 'bold',
        marginBottom: '.6rem',
    },
    ruleLabelActive: {
        color: colors.success,
    },
    ruleLabelNotActive: {
        color: colors.disabled
    }
};