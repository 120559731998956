import React from 'react';
import SensorPreviewIcon from './sensor_preview_icon';

const SirenIcon = props => {
    const {disabled, web, style} = props;

    return (
        <SensorPreviewIcon name={'siren'} disabled={disabled} web={web} style={style}/>
    );
};

export default SirenIcon;