import React, {Component} from 'react';

// in pixels
const LIMIT_SCROLL_MARGIN_BOTTOM = 150;

export default class BaseContainerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            direction: '',
            lastScrollPos: 0,
            maxHeight: 'calc(100% - 56px)',
            triggerBottomFunc: false,
        };

        this.changeMaxHeight = this.changeMaxHeight.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this.changeMaxHeight();
        window.addEventListener('resize', this.changeMaxHeight);
    }

    componentDidUpdate() {
        if (this.state.triggerBottomFunc) {
            this.setState({triggerBottomFunc: false});
            if (this.props.onBottomReached) this.props.onBottomReached();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.changeMaxHeight);
    }

    changeMaxHeight() {
        const menu_selector = '#menu';
        const html_el = document.getElementById(menu_selector);

        if (html_el) {
            const menu_height = html_el.clientHeight;
            this.setState({maxHeight: `calc(100% - ${menu_height}px)`});
        }
    }

    handleScroll(event) {
        const {scrollHeight, scrollTop, clientHeight} = event.currentTarget;

        if (this.state.lastScrollPos > scrollTop) {
            this.setState({
                direction: 'top',
                lastScrollPos: scrollTop,
                triggerBottomFunc: false,
            });
        } else if (this.state.lastScrollPos < scrollTop) {
            this.setState({
                direction: 'bottom',
                lastScrollPos: scrollTop,
                triggerBottomFunc: scrollHeight - scrollTop - clientHeight <= LIMIT_SCROLL_MARGIN_BOTTOM && !this.props.loading,
            });
        }
    }

    getWrapperStyle() {
        const {maxHeight} = this.state;
        return {
            ..._style.wrapper,
            maxHeight,
        };
    }

    render() {
        return (
            <div style={this.getWrapperStyle()} onScroll={this.handleScroll}>
                <div id='base-container-page' className='container' style={{..._style.container, ...this.props.style}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const _style = {
    container: {
        display: 'block',
        paddingTop: '.5rem',
        paddingBottom: '4.5rem',
    },
    wrapper: {
        overflowX: 'hidden',
        overflowY: 'auto',
    }
};