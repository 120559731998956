export default {
    COOKIE_INFO: {
        id: 'cookie_info_show',
        values: {
            true: 'true',
            false: 'false'
        },
        default: 'true'
    },
    USER: {
        id: 'user',
        default: ''
    }
};