import lan from '../i18n';

//const DAYS = lan.Days;
const DAYS_SHORT = lan.Days_short;
const FROM = lan.Time_from;
const TO = lan.Time_to;

//export const getDayByNum = num => {
//    if (Number.isNaN(num) || num < 0 || num > 6) return null;
//
//    return DAYS[num];
//};

export const getActiveRulesCount = rules_obj => {
    if (!rules_obj) return 0;

    const {results} = rules_obj;
    if (results && Array.isArray(results)) {
        const {length} = results.filter(r => r.active);
        return length;
    }

    return 0;
};

const get_short_format_time = time => {
    if (!time) return null;

    // time format --> hh:mm:ss || hh:mm
    const TIME_FULL_REGEX = /^\d{2}:\d{2}:\d{2}$/;
    const TIME_SHORT_REGEX = /^\d{2}:\d{2}$/;
    const TIME_LEAD_ZEROS = /:0{2}$/;

    if (time.match(TIME_FULL_REGEX)) {
        time = time.substring(0, 5);
    }

    if (time.match(TIME_SHORT_REGEX)) {
        if (time.match(TIME_LEAD_ZEROS)) {
            time = time.substring(0, 2);
        }

        return time;
    }

    return null;
};

export const getTimeFilterShortDescription = (time_filter) => {
    if (!time_filter || !time_filter.days) return null;

    return time_filter.days.map(d => DAYS_SHORT[d]).join(', ')
        + ` ${FROM} ` + get_short_format_time(time_filter.start) + ` ${TO} ` + get_short_format_time(time_filter.end);
};