import React, {Component} from 'react';

const STATE = Object.freeze({
    SHOW: 0,
    SHOWN: 1,
    HIDE: 2,
    HIDDEN: 3
});

export default class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            state: props.show ? STATE.SHOW : STATE.HIDDEN
        };
    }

    modalBehavior() {
        const {id, onShow, onShown, onHide, onDismiss} = this.props;

        const jcomp = $(`#${id}`);  // eslint-disable-line no-undef

        // set behavior
        jcomp.on('show.bs.modal', () => {
            this.setState({
                state: STATE.SHOW
            });

            if (onShow) {
                onShow();
            }
        });

        jcomp.on('shown.bs.modal', () => {
            this.setState({
                state: STATE.SHOWN
            });

            if (onShown) {
                onShown();
            }
        });

        jcomp.on('hide.bs.modal', () => {
            this.setState({
                state: STATE.HIDE
            });

            if (onHide) {
                onHide();
            }
        });

        jcomp.on('hidden.bs.modal', () => {
            this.setState({
                state: STATE.HIDDEN
            });

            if (onDismiss) {
                onDismiss();
            }
        });
    }

    showHide() {
        const {id, show} = this.props;
        const {state} = this.state;
        const jcomp = $(`#${id}`);  // eslint-disable-line no-undef

        // show or hide
        if (show && state !== STATE.SHOW && state !== STATE.SHOWN) {
            jcomp.modal('show');
        }
        if (!show && state !== STATE.HIDE && state !== STATE.HIDDEN) {
            jcomp.modal('hide');
        }
    }

    componentDidMount() {
        this.modalBehavior();
        this.showHide();
    }

    componentWillUnmount() {
        const {state} = this.state;

        if (state !== STATE.HIDE && state !== STATE.HIDDEN) {
            $(`#${this.props.id}`).modal('hide');  // eslint-disable-line no-undef
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.showHide();
        }
    }

    render() {
        const {id, canDismiss = true, style, children} = this.props;
        const dataBackdrop = canDismiss || 'static';

        return (
            <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-hidden="true"
                 data-backdrop={dataBackdrop} style={style}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            canDismiss ?
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span className="modal_close_btn" aria-hidden="true">&times;</span>
                                </button>
                                : null
                        }
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}
