export default {
    primaryTransparent: '#0816d840',
    primaryLight: '#8495d8',
    primary: '#0816d8',
    primaryDark: '#150859',
    secondaryTransparent: '#f6cb1940',
    secondaryLight: '#ffef8e',
    secondary: '#f6cb19',
    secondaryDark: '#917811',
    successTransparent: '#3b9c1540',
    successLight: '#abe298',
    success: '#3c9b15',
    successDark: '#244B11',
    errorTransparent: '#d2352640',
    errorLight: '#ffa798',
    error: '#d23526',
    errorDark: '#822313',
    disabledTransparent: '#91919140',
    disabledLight: '#d7d7d7',
    disabled: '#919191',
    disabledDark: '#464646',
    creamTransparent: '#fffdd040',
    creamLight: '#feffe5',
    cream: '#fffdd0',
    creamDark: '#a09f87',
    disabled_alarm: '#f4a232',
    disabled_alarm_text: '#ffffff',
};