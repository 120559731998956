import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';

class ReactModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        const {show} = this.props;
        this.setState({show});
    }

    onShow() {
        const {name} = this.props;
        window.setTimeout(() => {
            this.setState({show: false});

            // for not fetching and reloading same api and page every 2s
            if (name == "modalErrorMsg") {
                // navigate to previous tab
                window.history.go(-1);
            } else {
                window.location.reload();
            }

        }, 2000);
    }

    onHide() {
        if (this.state.show) {
            this.setState({show: false});
        }
    }

    render() {
        const {name, text} = this.props;
        const class_name = `modalMessage ${name}`;

        return (
            <Modal
                show={this.state.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName={class_name}
                onShow={this.onShow}
                onHide={this.onHide}
            >
                <Modal.Body>
                    <p className="modalBody">
                        {text}
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ReactModal;