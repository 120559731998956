import React, {Component} from 'react';
import {connect} from "react-redux";

import SensorList from "./sensor_list";
import {SENSOR_MODE} from "../../values/action_types";
import colors from "../../values/colors";
import {isObject, getBorderText3} from "../../utils/utils";

class SensorsDashboard extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {plant, status_obj} = this.props;
        if (!isObject(plant)) throw new EvalError('"plant" is required and must be an object');

        const {name} = plant;

        return (
            <div className='sensor-dashboard-container'>
                <div className='sensor-dashboard-track' style={_style.container}>
                    <h1 className='page-title' style={_style.title}>{name}</h1>
                    <SensorList track={plant} mode={SENSOR_MODE} status_obj={status_obj}/>
                </div>
            </div>
        );
    }
}

export default connect()(SensorsDashboard);

const _style = {
    title: {
        display: 'block',
        textAlign: 'center',
        margin: '.5rem 0 0.5rem 0',
        color: colors.creamLight,
        textShadow: getBorderText3(colors.disabledDark),
        padding: '2rem',
    },
    header: {
        padding: '.8rem 1rem',
        border: '1.5px solid',
        backgroundColor: colors.secondaryLight,
        borderColor: colors.secondaryDark,
        borderRadius: 16,
    },
};
