import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from 'react-router-dom';
import {translate} from 'react-translate';

import {Modal} from '../../base_components';
import MenuItem from "../menu_item";

import {logout, setNavigationLocation} from "./actions";

import {MenuItems as menu_items, MenuStringItems} from "../../../values/menu";
import colors from '../../../values/colors';

import {log} from "../../../utils/utils";
import {setBrowserTitleByPathname} from "../../../utils/navigation_utils";

class Menu extends Component {
    constructor(props) {
        super(props);

        this.modal_id = 'navbarModal';
        this.logout_confirm_modal_id = 'logout_confirm_modal';

        this.state = {
            show_logout_confirm_modal: false,
        };
    }

    componentDidMount() {
        this.setDocumentTitle();

        this.props.setNavigationLocation(this.props.history.location);

        this.props.history.listen((location, action) => {
            log('History changed with action "' + action + '". New location: ', location);

            this.props.setNavigationLocation(location);
        });
    }

    componentDidUpdate() {
        this.setDocumentTitle();
        this.historyChange();
    }

    setDocumentTitle() {
        const {pathname = ''} = this.props.navigation.location;
        setBrowserTitleByPathname(pathname);
    }

    historyChange() {
        const {redirect} = this.props.navigation;
        if (redirect) {
            this.props.history.replace(redirect);
            // this.props.resetNavigationRedirect();
        }
    }

    hideModal() {
        $(`#${this.modal_id}`).modal('hide');  // eslint-disable-line no-undef
    }

    showLogoutConfirmModal() {
        this.setState({show_logout_confirm_modal: true});
    }

    hideLogoutConfirmModal() {
        this.setState({show_logout_confirm_modal: false});
    }

    logoutConfirmed() {
        this.hideLogoutConfirmModal();
        this.props.logout();
    }

    renderLogoutConfirmModal() {
        const {t} = this.props;

        return (
            <Modal id={this.logout_confirm_modal_id} show={this.state.show_logout_confirm_modal}>
                <div className="modal-body">
                    <p>{t('logout_question')}</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary"
                            onClick={() => this.logoutConfirmed()}>{t('logout_confirm')}
                    </button>
                    <button type="button" className="btn btn-secondary"
                            onClick={() => this.hideLogoutConfirmModal()}>{t('logout_cancel')}
                    </button>
                </div>
            </Modal>
        );
    }

    validateMenuByPermissions() {
        const {user} = this.props.user;
        let config = true;
        let sensor_dashboard = true;
        // copy for not changing original values
        let validated_menu_items = [...menu_items];

        if (!user) {
            // not show menu items if user is not logged in
            validated_menu_items = [];
        } else if (user.permissions && !user.is_staff) {
            config = user.permissions.includes("update_sensor_configuration");
            sensor_dashboard = user.permissions.includes("has_remote_control");

            // remove from menu if don't have permissions
            if (!config) {
                const config_index  = validated_menu_items.findIndex(item => item.text == MenuStringItems.CONFIGURATION);
                validated_menu_items.splice(config_index, 1);
            }
            if (!sensor_dashboard) {
                const config_index  = validated_menu_items.findIndex(item => item.text == MenuStringItems.RACE);
                validated_menu_items.splice(config_index, 1);
            }
        }

        return validated_menu_items;
    }

    render() {
        // const {pathname = ''} = this.props.navigation.location;
        const {t, user} = this.props;

        // remove menu items if user don't have permissions
        let menu_items = this.validateMenuByPermissions();

        return (
            <nav id='menu' className="navbar navbar-expand-lg navbar-light" style={_style.menu}>
                {this.renderLogoutConfirmModal()}

                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src="/static/assets/img/logo-eyetrack.png" height="30" className="d-inline-block align-top"
                             alt="logo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="modal"
                            data-target="#navbarModal" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation" style={_style.toggleButton}>
                        <span className="navbar-toggler-icon" style={_style.toggleButtonIcon}></span>
                    </button>

                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav mr-auto">
                            {
                                menu_items.map((menuItemObject, index) =>
                                    <MenuItem menuItemObject={menuItemObject} key={index}/>
                                )
                            }
                        </ul>
                        {
                            user && user.isAuthenticated
                                ? <button onClick={() => this.showLogoutConfirmModal()} style={_style.logout}>{t('logout')}</button>
                                : null
                        }
                    </div>

                    <div className="modal fade" id={this.modal_id} tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content" style={_style.mobileMenu}>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        style={_style.closeButton}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="modal-body">
                                    <ul className="navbar-nav mr-auto">
                                        {
                                            menu_items.map(({text, href}) =>
                                                <li style={_style.mobileMenuItem} key={href}>
                                                    <Link className='nav-link' to={href}
                                                          onClick={() => this.hideModal()}
                                                          style={_style.mobileMenuItemLink}>{text}</Link>
                                                </li>
                                            )
                                        }
                                        <li style={_style.mobileMenuItem}>
                                            <a className='nav-link' style={_style.mobileMenuItemLink}
                                               onClick={() => this.showLogoutConfirmModal()}
                                               data-dismiss="modal">{t('logout')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = ({navigation, user}) => {
    return {navigation, user};
};

export default connect(mapStateToProps, {
    logout,
    setNavigationLocation,
})(withRouter(translate('Menu')(Menu)));

const _style = {
    menu: {
        backgroundColor: colors.primaryDark,
        borderRadius: 0,
    },
    toggleButton: {
        background: colors.secondary,
        borderColor: '#fff',
    },
    toggleButtonIcon: {
        backgroundImage: "url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' " +
            "xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' " +
            "stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E\")",
    },
    mobileMenu: {
        padding: '.5rem',
        borderRadius: 4,
        background: '#efefef',
        border: 'none',
        fontSize: '1.5rem',
    },
    mobileMenuItem: {
        textAlign: 'center',
    },
    mobileMenuItemLink: {
        display: 'inline-block',
        color: '#323232',
        // textShadow: '0 0 20px #000'
    },
    closeButton: {
        color: '#323232',
        fontSize: '3rem',
        opacity: 1,
        fontWeight: 100,
    },
    logout: {
        color: '#fff',
        background: 'none',
        border: 'none',
    },
};