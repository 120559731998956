import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {translate} from "react-translate";
import {connect} from 'react-redux';

import {BaseContainerPage} from "../../base_components";

import urls from '../../../values/urls';

class NotFoundPage extends Component {
    render() {
        const { t } = this.props;
        return (
            <BaseContainerPage>
                <h1 className='page-title'>{t("title")}</h1>
                <h1 className='page-subtitle'>
                    {t("subtitle")} <Link to={urls.DASHBOARD_BASE}>home</Link>
                </h1>
            </BaseContainerPage>
        );
    }
}

export default connect()(translate('404')(NotFoundPage));
