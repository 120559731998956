import React, {Component} from 'react';
import {translate} from 'react-translate';

import colors from "../../../values/colors";
import urls from "../../../values/urls";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

//    componentDidCatch(error, errorInfo) {
//        // You can also log the error to an error reporting service
//        // logErrorToMyService(error, errorInfo);
//    }

    render() {
        if (this.state.hasError) {
            const {t} = this.props;

            return (
                <div style={_style.container}>
                    <img src="/static/assets/img/logo-eyetrack.png" height="43" className="mb-4"
                         alt="logo"/>

                    <h2
                        className="mb-4"
                        style={{..._style.text}}
                    >{t('title')}</h2>

                    <a
                        className="btn btn-sm btn-outline-light text-center"
                        href={urls.DASHBOARD_BASE}
                    >{t('navigateToHome')}</a>
                </div>
            );
        }

        return this.props.children;
    }
}

export {ErrorBoundary}; // for testing
export default translate("ErrorBoundary")(ErrorBoundary);

const _style = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: colors.primaryDark
    },
    text: {
        color: 'white',
        textAlign: 'center'
    }
};
