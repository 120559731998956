import axios from "axios";

import {buildActionObject, getApiPath, handleError} from "../../../utils/actions_utils";
import {
    NOTIFICATIONS_LOADING,
    NOTIFICATIONS_READ_ERROR,
    NOTIFICATIONS_READ_SUCCESS
} from "../../../values/action_types";
import {NOTIFICATION_APP} from "../../../values/api_apps";
import {reqOptions} from "../../../utils/auth";

export const setNotificationAsRead = id => dispatch => {
    dispatch(buildActionObject(NOTIFICATIONS_LOADING));

    const url = getApiPath(NOTIFICATION_APP, `/notifications/${id}/`);

    return axios.post(url, null, reqOptions)
        .then(({data}) => dispatch(buildActionObject(NOTIFICATIONS_READ_SUCCESS, data)))
        .catch(error => handleError(error, dispatch, NOTIFICATIONS_READ_ERROR));
};
