import React from 'react';

import {errorMessageStyle} from "../../../values/style";
import {Errors} from "../../../values/strings";

const ErrorMessage = props => {
    const {text = Errors.ERROR_DEFAULT, children} = props;

    return (
        <p style={errorMessageStyle}>
            {children || text}
        </p>
    );
};

export default ErrorMessage;