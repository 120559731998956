import React, {Component} from 'react';
import {connect} from "react-redux";
import Slider from 'rc-slider';
import {translate} from 'react-translate';

import {renderMarks} from '../../../utils/configuration_utils';
import {fetchSensorConfig, saveSensorConfig} from "../../../routes/configuration/actions";
import {Loader} from '../../base_components';

class ConfigurationDetectionTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            target_size_far: "",
            target_size_near: "",
            min_speed_far: "",
            min_speed_near: "",
            distance_traveled_far: "",
            distance_traveled_near: ""
        };
        this.saveValues = this.saveValues.bind(this);
    }

    componentDidMount() {
        const {sensor_id} = this.props;
        const endpoint = `/sensor/${sensor_id}/detection/`;
        this.props.fetchSensorConfig(sensor_id, endpoint);
    }

    revertToDefault(type) {
        const {default_values} = this.props.sensorConfiguration.config;
        switch (type) {
            case 'target_size_far':
                this.setState({
                    target_size_far: default_values.target_size_far
                });
                break;
            case 'target_size_near':
                this.setState({
                    target_size_near: default_values.target_size_near
                });
                break;
            case 'min_speed_far':
                this.setState({
                    min_speed_far: default_values.min_speed_far
                });
                break;
            case 'min_speed_near':
                this.setState({
                    min_speed_near: default_values.min_speed_near
                });
                break;
            case 'distance_traveled_far':
                this.setState({
                    distance_traveled_far: default_values.distance_traveled_far
                });
                break;
            case 'distance_traveled_near':
                this.setState({
                    distance_traveled_near: default_values.distance_traveled_near
                });
                break;
        }

    }

    onSliderChange(type, value) {
        switch (type) {
            case 'target_size_far':
                this.setState({
                    target_size_far: value
                });
                break;
            case 'target_size_near':
                this.setState({
                    target_size_near: value
                });
                break;
            case 'min_speed_far':
                this.setState({
                    min_speed_far: value
                });
                break;
            case 'min_speed_near':
                this.setState({
                    min_speed_near: value
                });
                break;
            case 'distance_traveled_far':
                this.setState({
                    distance_traveled_far: value
                });
                break;
            case 'distance_traveled_near':
                this.setState({
                    distance_traveled_near: value
                });
                break;
        }
    }

    isLoading() {
        const {sensorConfiguration} = this.props;
        return sensorConfiguration.loading;
    }

    saveValues() {
        const {sensor_id} = this.props;
        const endpoint = `/sensor/${sensor_id}/detection/`;
        const data = this.state;
        this.props.saveSensorConfig(sensor_id, endpoint, data);
    }

    componentDidUpdate(prevProps) {
        // update values in slider after fetching configuration
        if (this.props.sensorConfiguration.config &&
            this.props.sensorConfiguration.config.current_values &&
            prevProps.sensorConfiguration.config != this.props.sensorConfiguration.config) {
                const {current_values} = this.props.sensorConfiguration.config;
                this.setState({
                    target_size_far: current_values.target_size_far,
                    target_size_near: current_values.target_size_near,
                    min_speed_far: current_values.min_speed_far,
                    min_speed_near: current_values.min_speed_near,
                    distance_traveled_far: current_values.distance_traveled_far,
                    distance_traveled_near: current_values.distance_traveled_near
                });
        }
    }

     render() {

        return (
            <div>
                <Loader loading={this.isLoading()}/>

                <div className="configTabTitle">
                    Configure detection parameters
                </div>
                <div className="configTabSubtitle">
                    Far and near fields are referring to respectively the region of the image that is far and near the sensor.
                </div>

                <p className="configDividerTitle">Target Size</p>
                <hr className="configDivider"/>
                <p className="configDividerSubtitle">
                    Size of an object that can provoke an alarm, from far and near distance.
                    Increase if the sensor does not catch well the alarms.
                </p>
                <div className="configTargetSize">
                    <div className="configSlider">
                        <p className="configName">Target size Far</p>
                        <Slider
                            min={1}
                            max={2}
                            marks={renderMarks(1, 2, 1)}
                            step={1}
                            value={this.state.target_size_far}
                            onChange={ (value) => this.onSliderChange('target_size_far', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('target_size_far') }>
                            Revert to default
                        </button>
                    </div>

                    <div className="configSlider">
                        <p className="configName">Target size Near</p>
                        <Slider
                            min={2}
                            max={6}
                            marks={renderMarks(2, 6, 1)}
                            step={1}
                            value={this.state.target_size_near}
                            onChange={ (value) => this.onSliderChange('target_size_near', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('target_size_near') }>
                            Revert to default
                        </button>
                    </div>
                </div>

                <p className="configDividerTitle">Min speed</p>
                <hr className="configDivider"/>
                <p className="configDividerSubtitle">
                    Maximum speed at which an object is considered to slow or still.
                    Reduce in case of false alarms when an object is moving slowly
                </p>
                <div className="configMinSpeed">
                    <div className="configSlider">
                        <p className="configName">Min speed Far</p>
                        <Slider
                            min={1}
                            max={4}
                            marks={renderMarks(1, 4, 1)}
                            step={1}
                            value={this.state.min_speed_far}
                            onChange={ (value) => this.onSliderChange('min_speed_far', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('min_speed_far') }>
                            Revert to default
                        </button>
                    </div>

                    <div className="configSlider">
                        <p className="configName">Min speed Near</p>
                        <Slider
                            min={4}
                            max={12}
                            marks={renderMarks(4, 12, 1)}
                            step={1}
                            value={this.state.min_speed_near}
                            onChange={ (value) => this.onSliderChange('min_speed_near', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('min_speed_near') }>
                            Revert to default
                        </button>
                    </div>
                </div>

                <p className="configDividerTitle">Distance Traveled</p>
                <hr className="configDivider"/>
                <p className="configDividerSubtitle">
                    Minimum size of an object that can provoke an alarm, from far and near distance.
                    Decrease if the sensor does not catch well the alarms.
                </p>
                <div className="configDistanceTraveled">
                    <div className="configSlider">
                        <p className="configName">Distance traveled Far</p>
                        <Slider
                            min={1}
                            max={10}
                            marks={renderMarks(1, 10, 1)}
                            step={1}
                            value={this.state.distance_traveled_far}
                            onChange={ (value) => this.onSliderChange('distance_traveled_far', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('distance_traveled_far') }>
                            Revert to default
                        </button>
                    </div>

                    <div className="configSlider">
                        <p className="configName">Distance traveled Near</p>
                        <Slider
                            min={3}
                            max={20}
                            marks={renderMarks(3, 20, 1)}
                            step={1}
                            value={this.state.distance_traveled_near}
                            onChange={ (value) => this.onSliderChange('distance_traveled_near', value) }
                        />
                        <button className="revertBtn" onClick={ () => this.revertToDefault('distance_traveled_near') }>
                            Revert to default
                        </button>
                    </div>
                </div>
                <div className="configSaveTab">
                    <button className="configSave" onClick={this.saveValues}>
                        Save
                    </button>
                </div>
            </div>
        );
    }
}


function mapStateToProps({sensorConfiguration}) {
    return {sensorConfiguration};
}

export default connect(mapStateToProps, {
    fetchSensorConfig, saveSensorConfig
})(translate('ConfigurationDetectionTab')(ConfigurationDetectionTab));
