import React, {Component} from 'react';
import {translate} from "react-translate";
import axios from "axios";

import {getApiPath} from "../../../utils/actions_utils";
import * as api_apps from "../../../values/api_apps";
import {isFunction, log} from "../../../utils/utils";



export const ALL_PLANTS_ID = -1;
export const NO_PLANTS_ID = -2;
export const PLANTS_COUNT_LIMIT = 3;

class PlantSelect extends Component {
    constructor(props) {
        super(props);

        const {t} = props;
        this.no_plants_option = {
            id: NO_PLANTS_ID,
            name: t('no_plants')
        };

        this.state = {
            default_id: props.default_id || ALL_PLANTS_ID,
            plants: [{
                id: ALL_PLANTS_ID,
                name: t('all_plants')
            }]
        };
    }

    componentDidMount() {
        // fetch all plants data. This data is used to fill the plant multiple select
        const url = getApiPath(api_apps.SENSOR_APP, '/plants/?page_size=100');

        axios.get(url, {withCredentials: true})
            .then(res => this.onPlantsReceived(res))
            .catch((err) => log(err));
    }

    onPlantsReceived({data}) {
        const plants_count = (data.results || []).length;
        const show_all = plants_count <= PLANTS_COUNT_LIMIT;

        // this is for dashboard page (change the plants show state)
        const {onPlantListFetchSuccess} = this.props;
        if (isFunction(onPlantListFetchSuccess)) {
            onPlantListFetchSuccess(show_all, data.results || []);
        }

        let options = [
            ...this.state.plants,
            ...data.results.map(p => ({
                id: p.id,
                name: p.name
            }))
        ];
        // set new plant list based on plants count
        const plants = plants_count <= PLANTS_COUNT_LIMIT
            ? options
            : [this.no_plants_option, ...options];
        // set new default id based on props.default_id existence (plant page) or plants count
        const default_id = this.props.default_id
            ? this.props.default_id
            : plants_count <= PLANTS_COUNT_LIMIT
                ? ALL_PLANTS_ID
                : NO_PLANTS_ID;

        this.setState({plants, default_id});
    }

    onSelectChange(event) {
        const {onNoPlantSelected, onAllPlantsSelected} = this.props;
        const {value} = event.target;

        if (parseInt(value) === NO_PLANTS_ID) {

            if (isFunction(onNoPlantSelected)) onNoPlantSelected();
            this.setState({default_id: NO_PLANTS_ID});

        } else if (parseInt(value) === ALL_PLANTS_ID) {

            if (isFunction(onAllPlantsSelected)) onAllPlantsSelected();
            this.setState({default_id: ALL_PLANTS_ID});

        } else if (isNaN(parseInt(value))) window.location.href = value;
    }

    render() {
        const {t, selectValue, dashboard_mode} = this.props;
        const {plants, default_id} = this.state;
        let result = null;

        // render select dropdown if there is more then one track
        // 1 is always added as "All tracks", so checking if more then 2
        if (plants.length > 2) {
            result = (
                <div className='form-inline' style={_style.selectTrackForm}>
                    <label className='mobile-invisible' htmlFor="select-track" style={_style.selectTrackLabel}>
                        {t('select_plant')}
                    </label>
                    <select
                        id='select-track'
                        className='form-control'
                        value={selectValue(default_id, dashboard_mode)}
                        onChange={this.onSelectChange.bind(this)}
                    >
                        {plants.map(({id, name}) => <option value={selectValue(id, dashboard_mode)} key={id}>{name}</option>)}
                    </select>
                </div>
            );
        } 

        return result;
    }
}

export default translate('HomePage')(PlantSelect);

const _style = {
    selectTrackForm: {
        margin: '0 0 .75rem 0',
    },
    selectTrackLabel: {
        margin: '0 1rem 0 0',
    },
};