import React, {Component} from 'react';
import {BaseContainerPage} from "../../base_components";

export default class SentryErrorGeneratorPage extends Component {
    render() {
        return (
            <BaseContainerPage>
                <h1 className='page-title'>Sentry error generator</h1>

                <button onClick={'notAFunction'}>Click me</button>
            </BaseContainerPage>
        );
    }
}