import React, {Component} from 'react';

export default class Grid extends Component {
    render() {
        const {className, children, style} = this.props;

        return (
            <div className={`grid ${className || ''}`} style={{..._style.grid, ...style}}>
                {children}
            </div>
        );
    }
}

const _style = {
    grid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        WebkitFlexWrap: 'wrap',
        flexWrap: 'wrap',
    }
};