import React from 'react';

import EditRuleModal from './rule_edit_modal';

const RuleNewModal = props => {
    const {...rest} = props;

    return (
        <EditRuleModal {...rest} newRule/>
    );
};

export default RuleNewModal;