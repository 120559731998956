import urls from "../values/urls";
import {PageTitles} from "../values/strings";

export const setBrowserTitleByPathname = pathname => {
    if (!!pathname && !!document) {
        let title;

        if (pathname.startsWith(urls.DASHBOARD_BASE)) title = PageTitles.TRAINING;
        else if (pathname.startsWith(urls.ALARMS_BASE)) title = PageTitles.ALARMS;
        else if (pathname.startsWith(urls.IMAGES_BASE)) title = PageTitles.IMAGES;
        else if (pathname.startsWith(urls.NOTIFICATIONS_BASE)) title = PageTitles.NOTIFICATIONS;
        else if (pathname.startsWith(urls.SETTINGS_BASE)) title = PageTitles.SETTINGS;
        else if (pathname.startsWith(urls.LOGIN)) title = PageTitles.LOGIN;
        else if (pathname.startsWith(urls.DEV_TEST)) title = PageTitles.DEV_TEST;
        else if (pathname.startsWith(urls.CONFIGURATION_BASE)) title = PageTitles.CONFIGURATION;
        else if (pathname.startsWith(urls.SENSORS_BASE)) title = PageTitles.RACE;
        else title = PageTitles.NOT_FOUND;

        document.title = title;
    }
};