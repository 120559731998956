import _ from 'lodash';

import colors from '../values/colors';
import {BatteryStatus, BatteryStatusApi, SensorStatus} from "../values/statuses";
import {types as CommandTypes} from '../values/commands';

export const getSensorStatus = (sensor, type = CommandTypes.PLANT_MODE, alarm_time = 1000 * 60 * 5) => {
    let sensor_status = SensorStatus.OFFLINE;
    if (sensor && sensor.last_status) {
        const {idle, night_mode, status} = sensor.last_status;
        const {insert_date} = sensor.alarm_status;
        const time_diff = Date.now() - (new Date(insert_date)).getTime();
        
        if (time_diff < alarm_time && idle && type != CommandTypes.SENSOR_SWITCH) {
            sensor_status = SensorStatus.ALARM_DISABLED;

        } else if (time_diff < alarm_time) {
            sensor_status = SensorStatus.ALARM;

        } else if (idle && type != CommandTypes.SENSOR_SWITCH) {
            sensor_status = SensorStatus.DISABLED;

        } else if (night_mode) {
            sensor_status = SensorStatus.SLEEP;

        } else if (status) {
            sensor_status = SensorStatus.ONLINE;
        }
    }

    return sensor_status;
};

export const getBatteryStatusByApi = battery_status_api => {
    // if (!battery_status_api || typeof battery_status_api !== 'string') throw new EvalError('\"battery_status_api\" must be a non-empty string');
    // if (!_.values(BatteryStatusApi).includes(battery_status_api)) throw new TypeError(`\"${battery_status_api}\" is not valid`);

    let status;

    switch (battery_status_api) {
        case BatteryStatusApi.EMPTY:
            status = BatteryStatus.EMPTY;
            break;
        case BatteryStatusApi.LOW:
            status = BatteryStatus.LOW;
            break;
        case BatteryStatusApi.MEDIUM:
            status = BatteryStatus.MEDIUM;
            break;
        case BatteryStatusApi.FULL:
            status = BatteryStatus.FULL;
            break;
        default:
            status = battery_status_api;
            break;
    }

    return status;
};

export const getColorByStatus = status => {
    let color;
    switch (status) {
        case SensorStatus.ONLINE:
            color = colors.success;
            break;
        case SensorStatus.OFFLINE:
            color = colors.disabled;
            break;
        case SensorStatus.SLEEP:
            color = colors.primaryLight;
            break;
        case SensorStatus.ALARM:
            color = colors.error;
            break;
        case SensorStatus.DISABLED:
            color = colors.disabled;
            break;
        default:
            color = colors.secondary;
            break;
    }

    return color;
};

export const getForeColorByStatus = status => {
    let color = '#fff';

    if (status === SensorStatus.DISABLED) {
        color = colors.error;
    }

    return color;
};

/**
 *
 * @param plant_name
 * @param plant_list_obj
 * @returns {*}
 */
export const getPlantIdByName = (plant_name, plant_list_obj) => {
    if (!plant_name || !plant_list_obj) return undefined;

    const list = _.values(plant_list_obj);
    const plant = list.find(p => p.name === plant_name);

    if (plant === undefined) return undefined;

    return plant.id;
};

/**
 *
 * @param sensor_name
 * @param sensor_list
 * @returns {*}
 */
export const getSensorIdByName = (sensor_name, sensor_list) => {
    if (!sensor_name || !sensor_list) return undefined;

    // startsWith also covers total equivalent cases
    const sensor = sensor_list.find(s => s.name.startsWith(sensor_name));

    if (sensor === undefined) return undefined;

    return sensor.id;
};
