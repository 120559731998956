import React, {Component} from 'react';
import {connect} from "react-redux";

import Notification from '../notification';

import {newNotificationReceived} from "./actions";

import {NOTIFICATION_APP} from "../../../values/api_apps";
import {getWebSocketURL, log} from "../../../utils/utils";

const Status = Object.freeze({
    SHOW: 10,
    HIDE: -80,
});

class PushNotification extends Component {
    constructor(props) {
        super(props);

        this.htmlId = 'push-notification';
        this.animationOptions = {
            duration: 250,
        };
        this.time_shown = 3333;
        this.websocket = null;
        this.websocket_url = getWebSocketURL(NOTIFICATION_APP, '/notifications/');

        this.state = {
            notification: {},
            status: Status.HIDE,
        };
    }

    componentDidMount() {
        this.setupWebsocket();

        // this.setState({status: Status.SHOW});
    }

    componentDidUpdate(prevProps) {
        const {htmlId, animationOptions} = this;

        const complete = this.getAnimationCompleteFunction();
        const css = this.getCssProps();

        $(`#${htmlId}`).animate(css, {  // eslint-disable-line no-undef
            ...animationOptions,
            complete,
        });

        // user update
        if (prevProps && prevProps.user
            && this.props && this.props.user
            && this.props.user.isAuthenticated !== prevProps.user.isAuthenticated) this.setupWebsocket();
    }

    setupWebsocket() {
        if (this.props.user.isAuthenticated) {
            // setup websocket connection
            this.websocket = new WebSocket(this.websocket_url);

            this.websocket.onopen = function () {
                log('Notification websocket open');
            };

            this.websocket.onmessage = e => {
                const notification = JSON.parse(e.data);

                log('New notification received');
                log(notification);

                this.props.newNotificationReceived(notification);
                this.setState({status: Status.SHOW, notification});
            };

            this.websocket.onclose = function () {
                log('Notification websocket closed');
            };
        } else if (!!this.websocket) {
            this.websocket.close();
            this.websocket = null;
        }
    }

    resetState() {
        this.setState({
            // notification: {},
            status: Status.HIDE,
        });
    }

    getAnimationCompleteFunction() {
        const {time_shown} = this;
        const {status} = this.state;

        let fun;

        switch (status) {
            case Status.SHOW:
                fun = () => setTimeout(() => this.resetState(), time_shown);
                break;
            default:
                fun = () => {
                };
                break;
        }

        return fun;
    }

    getCssProps() {
        const {status} = this.state;

        return {
            top: status,
            opacity: status === Status.SHOW ? 1 : 0,
        };
    }

    render() {
        const {htmlId} = this;
        const {notification = {}} = this.state;

        return (
            <div id={htmlId} className='push-notification' style={_style.container}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        style={_style.closeButton} onClick={() => this.resetState()}>
                    <span aria-hidden="true">&times;</span>
                </button>

                <Notification notificationObject={notification} style={_style.body} iconStyle={_style.icon}/>
            </div>
        );
    }
}

const mapStateToProps = ({user}) => {
    return {user};
};

export default connect(mapStateToProps, {newNotificationReceived})(PushNotification);

const _style = {
    container: {
        display: 'block',
        position: 'absolute',
        width: 'calc(100% - 20px)',
        maxWidth: 440,
        height: 80,
        backgroundColor: '#fff',
        border: '1px solid #aaa',
        borderRadius: 5,
        zIndex: 9999,
        top: -80,
        left: '50%',
        transform: 'translateX(-50%)',
        opacity: 0,
        boxShadow: '0 -1px 18px 6px rgba(0,0,0,.4)',
        padding: 8,
    },
    closeButton: {
        height: 24,
        width: 24,
        position: 'absolute',
        top: 0,
        right: 0,
    },
    body: {
        padding: 0,
    },
    icon: {
        width: 64,
        height: 64,
    },
};