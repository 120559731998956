import axios from "axios";

import {isObject} from "../../../utils/utils";
import {buildActionObject, getApiPath, handleError} from "../../../utils/actions_utils";
import {LOGIN_ERROR, LOGIN_LOADING, LOGOUT, NAVIGATION_SET_LOCATION} from "../../../values/action_types";
import {USERS_APP as APP_NAME} from "../../../values/api_apps";
import {reqOptions} from "../../../utils/auth";

export const setNavigationLocation = location => {
    if (!isObject(location)) throw new EvalError('\'location\' must be specified as an object');

    return buildActionObject(NAVIGATION_SET_LOCATION, location);
};

export const logout = () => dispatch => {
    dispatch(buildActionObject(LOGIN_LOADING));

    const url = getApiPath(APP_NAME, '/logout/');

    return axios.post(url, null, reqOptions)
        .then(() => dispatch(buildActionObject(LOGOUT)))
        .catch(error => handleError(error, dispatch, LOGIN_ERROR));
};