import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import {Icon} from '../base_components';

class MenuItem extends Component {
    constructor(props) {
        super(props);

        this.refText = React.createRef();
        this.state = {hover: false, mounted: false};
    }

    componentDidMount() {
        this.setState({mounted: true});
    }

//    componentDidUpdate(prevProps, prevState, snapshot) {
//        // if (this.state.mounted) {
//        //     if (this.state.hover) {
//        //         $(this.refText.current).collapse('show');
//        //     } else {
//        //         $(this.refText.current).collapse('hide');
//        //     }
//        // }
//    }

    componentWillUnmount() {
        this.setState({mounted: false});
    }

    onHover() {
        this.setState({hover: true});
    }

    onLeave() {
        this.setState({hover: false});
    }

    getTextStyle() {
        const {icon} = this.props.menuItemObject;

        if (!icon) return _style.text;

        return {
            ..._style.text,
            marginLeft: 0,
        };
    }

    renderIcon() {
        const {icon} = this.props.menuItemObject;

        return icon
            ? <Icon name={icon} style={_style.icon} white/>
            : null;
    }

    render() {
        const {text, href} = this.props.menuItemObject;
        const {pathname = ''} = this.props.navigation.location;

        return (
            <li className={'nav-item ' + (pathname.startsWith(href) ? 'active' : '')}
                onMouseEnter={() => this.onHover()} onMouseLeave={() => this.onLeave()}>
                <Link className='nav-link' to={href} style={_style.menuItem}>
                    {/*{this.renderIcon()}*/}
                    <span ref={this.refText} className={/*icon ? 'collapse width' :*/ ''}
                          style={this.getTextStyle()}>{text}</span>
                </Link>
            </li>
        );
    }
}

const mapStateToProps = ({navigation}) => {
    return {navigation};
};

export default connect(mapStateToProps)(MenuItem);

const _style = {
    menuItem: {
        color: '#fff',
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
    },
    text: {
        marginLeft: '.5rem',
    },
    icon: {
        width: 24,
    },
};