import React, {Component} from 'react';
import {connect} from "react-redux";
import Slider from 'rc-slider';
import {translate} from 'react-translate';

import {renderMarks, renderPopup} from '../../../utils/configuration_utils';
import {fetchSensorConfig, saveSensorConfig} from "../../../routes/configuration/actions";
import {Loader} from '../../base_components';


class ConfigurationSensitivityTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sensitivity_far: "",
            sensitivity_near: "",
        };
        this.saveValues = this.saveValues.bind(this);
    }

    componentDidMount() {
        const {sensor_id} = this.props;
        const endpoint = `/sensor/${sensor_id}/sensitivity/`;
        this.props.fetchSensorConfig(sensor_id, endpoint);
    }

    revertToDefault(type) {
        const {default_values} = this.props.sensorConfiguration.config;
        switch (type) {
            case 'sensitivity_far':
                this.setState({
                    sensitivity_far: default_values.sensitivity_far
                });
                break;
            case 'sensitivity_near':
                this.setState({
                    sensitivity_near: default_values.sensitivity_near
                });
                break;
        }

    }

    onSliderChange(type, value) {
        switch (type) {
            case 'sensitivity_far':
                this.setState({
                    sensitivity_far: value,
                });
                break;
            case 'sensitivity_near':
                this.setState({
                    sensitivity_near: value,
                });
                break;
        }
    }

    isLoading() {
        const {sensorConfiguration} = this.props;
        return sensorConfiguration.loading;
    }
    
    saveValues() {
        const {sensor_id} = this.props;
        const endpoint = `/sensor/${sensor_id}/sensitivity/`;
        const data = this.state;
        this.props.saveSensorConfig(sensor_id, endpoint, data);
    }

    componentDidUpdate(prevProps) {
        // update values in slider after fetching configuration
        if (this.props.sensorConfiguration.config &&
            this.props.sensorConfiguration.config.current_values &&
            prevProps.sensorConfiguration.config != this.props.sensorConfiguration.config) {
                const {current_values} = this.props.sensorConfiguration.config;
                this.setState({
                    sensitivity_far: current_values.sensitivity_far,
                    sensitivity_near: current_values.sensitivity_near
                });
        }
    }

    render() {
        const {config} = this.props.sensorConfiguration;

        return (
            <div>
                <Loader loading={this.isLoading()}/>

                {renderPopup(config)}

                <div className="configTabTitle">
                    Configure sensitivity parameters
                </div>
                <div className="configTabSubtitle">
                    Lower sensitivity when there are too many false alarms, raise when alarms not detected
                </div>

                <p className="configDividerTitle">Sensitivity</p>
                <hr className="configDivider"/>
                <div className="configSlider">
                    <p className="configName">Sensitivity Far</p>
                    <Slider
                        min={0.4}
                        max={1.75}
                        marks={renderMarks(0.4, 1.75, 0.05)}
                        step={0.05}
                        value={this.state.sensitivity_far}
                        onChange={ (value) => this.onSliderChange('sensitivity_far', value) }
                    />
                    <button className="revertBtn" onClick={ () => this.revertToDefault('sensitivity_far') }>
                        Revert to default
                    </button>
                </div>

                <div className="configSlider">
                    <p className="configName">Sensitivity Near</p>
                    <Slider
                        min={0.9}
                        max={3}
                        marks={renderMarks(0.9, 3, 0.1)}
                        step={0.1}
                        value={this.state.sensitivity_near}
                        onChange={ (value) => this.onSliderChange('sensitivity_near', value) }
                    />
                    <button className="revertBtn" onClick={ () => this.revertToDefault('sensitivity_near') }>
                        Revert to default
                    </button>
                </div>
                <div className="configSaveTab">
                    <button className="configSave" onClick={this.saveValues}>
                        Save
                    </button>
                </div>

            </div>
        );
    }
}

function mapStateToProps({sensorConfiguration}) {
    return {sensorConfiguration};
}

export default connect(mapStateToProps, {
    fetchSensorConfig, saveSensorConfig
})(translate('ConfigurationSensitivityTab')(ConfigurationSensitivityTab));

