import React, {Component} from 'react';
import moment from "moment";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {setNotificationAsRead} from "./actions";

import {Icon} from '../../base_components';
import {isObject} from "../../../utils/utils";
import {getHrefByNotification, getIconNameByNotificationEventApi} from "../../../utils/notification_utils";

class Index extends Component {
    onClick() {
        const {onClick, notificationObject} = this.props;

        if (!isObject(notificationObject)) return null;

        const {id, read_on} = notificationObject;

        if (onClick) onClick();
        if (!read_on) this.props.setNotificationAsRead(id);
    }

    getHref() {
        const {notificationObject} = this.props;

        if (!isObject(notificationObject)) return null;

        return getHrefByNotification(notificationObject);
    }

    render() {
        const {title, notificationObject, style, iconStyle} = this.props;

        const {event, body: text, created_on: date, read_on: read} = notificationObject;
        const read_style = read ? _style.read : _style.notRead;

        // log(notificationObject);

        return (
            <Link to={this.getHref()} onClick={() => this.onClick()}
                  style={{..._style.container, ...read_style}}>
                <div className='notification' style={{..._style.notification, ...style}}>
                    {/*<img src={icon ? `../../assets/icons/icon_${icon}.svg` : ''} className='notification-icon'*/}
                    {/*style={_style.icon}/>*/}
                    <Icon name={getIconNameByNotificationEventApi(event)} colored
                          className={`notification-icon icon-${event}`}
                          style={{..._style.icon, ...iconStyle}}/>
                    <div className='notification-body' style={_style.body}>
                        {title ? <h1 className='notification-title' style={_style.title}>{title}</h1> : null}
                        {text ? <h2 className='notification-text' style={_style.text}>{text}</h2> : null}
                        {date ? <p className='notification-date'
                                   style={_style.date}>{moment(date).format('DD/MM/YYYY  HH:mm')}</p> : null}
                    </div>
                </div>
            </Link>
        );
    }
}

export default connect(null, {setNotificationAsRead})(Index);

const _style = {
    container: {
        width: '100%',
        maxHeight: '100%',
        borderBottom: '1px solid #ccc',
    },
    notification: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '.7rem',
        maxHeight: '100%',
    },
    icon: {
        margin: '0 1rem 0 0',
        maxHeight: '100%',
        // width: see style.css,
        // height: see style.css,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    title: {
        // fontSize: see style.css,
        color: '#000',
        margin: '0 0 .4rem 0',
    },
    text: {
        // fontSize: see style.css,
        color: '#505050',
        margin: '0 0 .8rem 0',
    },
    date: {
        fontSize: '.7rem',
        color: '#8a8a8a',
        margin: 0,
    },
    read: {
        backgroundColor: '#fff',
    },
    notRead: {
        backgroundColor: '#aecaff69',
    },
};