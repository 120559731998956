import {MenuStringItems} from "./menu";
import lang from '../i18n';

export const UserStrings = {
    LOGIN_ERROR_400: lang.UserStrings.LOGIN_ERROR_400,
};

export const Errors = {
    ERROR_DEFAULT: lang.Errors.ERROR_DEFAULT,
    ERROR_CONN_REFUSED: lang.Errors.ERROR_CONN_REFUSED,
    ERROR_401: lang.Errors.ERROR_401,
};

export const Messages = {
    LOADING: 'Caricamento...',
};

export const APPLICATION_NAME = 'EYE-TRACK';
const create_page_title = title => `${title} - ${APPLICATION_NAME}`;

export const PageTitles = {
    TRAINING: APPLICATION_NAME,
    RACE: create_page_title(MenuStringItems.RACE),
    ALARMS: create_page_title(MenuStringItems.ALARMS),
    IMAGES: create_page_title(MenuStringItems.IMAGES),
    NOTIFICATIONS: create_page_title(MenuStringItems.NOTIFICATIONS),
    SETTINGS: create_page_title(MenuStringItems.SETTINGS),
    LOGIN: create_page_title('Login'),
    DEV_TEST: create_page_title(MenuStringItems.DEV_TEST),
    CONFIGURATION: create_page_title(MenuStringItems.CONFIGURATION),
    NOT_FOUND: create_page_title('Pagina non trovata'),
};
