import React, {Component} from 'react';
import {translate} from 'react-translate';

import Modal from './modal';
import {isFunction} from "../../../utils/utils";

class ConfirmModal extends Component {
    constructor(props) {
        super(props);

        const {t} = props;

        this.htmlID = 'default_html_confirm_modal_id';
        this.title = 'Default confirm modal title';
        this.confirmText = t('confirm_btn');
        this.cancelText = t('cancel_btn');
    }

    getHTMLID() {
        return this.props.htmlID || this.htmlID;
    }

    getTitle() {
        return this.props.title || this.title;
    }

    getConfirmText() {
        return this.props.confirmText || this.confirmText;
    }

    getCancelText() {
        return this.props.cancelText || this.cancelText;
    }

    confirmClick() {
        const {onConfirmClick} = this.props;

        if (onConfirmClick && isFunction(onConfirmClick)) {
            onConfirmClick();
        }
    }

    cancelClick() {
        const {onCancelClick} = this.props;

        if (isFunction(onCancelClick)) {
            onCancelClick();
        }
    }

    render() {
        const {onCancelClick, onConfirmClick, onDismiss} = this.props;

        return (
            <Modal
                id={this.getHTMLID()}
                show={this.props.show}
                onDismiss={onDismiss}
            >
                <div className='modal-body'>
                    <p className='modal-title'>{this.getTitle()}</p>
                </div>

                <div className='modal-footer'>
                    <button className='btn btn-danger' data-dismiss="modal" onClick={() => onCancelClick()}>{this.getCancelText()}</button>
                    <button className='btn btn-primary' onClick={() => onConfirmClick()}>{this.getConfirmText()}</button>
                </div>
            </Modal>
        );
    }
}

export {ConfirmModal}; // for testing
export default translate('ConfirmModal')(ConfirmModal);