import React from 'react';

import {Icon} from '../base_components';

const DEFAULT_NAME = 'alarm';
const Status = Object.freeze({
    DISABLED: 'disabled',
    ON: 'on',
    OFF: 'off',
});

const getStatus = (disabled, web) => {
    if (!!disabled) return Status.DISABLED;
    if (!!web) return Status.ON;
    return Status.OFF;
};

const getStyle = additional_style => ({..._style.icon, ...additional_style});

const SensorPreviewIcon = props => {
    const {name, disabled, web, style} = props;
    const status = getStatus(disabled, web);

    // don't show the icon if icon/command is turned off (not disabled)
    if (status === Status.OFF) return null;

    // show bar icon if icon/command is disabled
    const bar = status === Status.DISABLED;

    // show colored icon if icon/command is turned on
    const colored = status === Status.ON;

    /*
    * NOTE that bar and colored are mutually exclusive
    * */

    return (
        <Icon name={name || DEFAULT_NAME} bar={bar} colored={colored} style={getStyle(style)}/>
    );
};

const _style = {
    icon: {
        width: 32,
    },
};

export default SensorPreviewIcon;