import React, {Component} from 'react';
import {Link} from "react-router-dom";

import '../../../../static/css/grid_element.css';

export default class GridElement extends Component {
    renderBackgroundImage() {
        const {backgroundUrl} = this.props;

        if (!backgroundUrl) return null;

        const backgroundImage = `url("${backgroundUrl}")`;

        return (
            <div style={{..._style.backgroundImg, backgroundImage}}/>
        );
    }

    renderOverlay() {
        const {overlay} = this.props;

        if (!overlay) return null;

        return (
            <div className='overlay' style={{
                ..._style.overlay,
                backgroundColor: overlay
            }}/>
        );
    }

    renderTitle() {
        const {title} = this.props;

        if (!title) return null;

        return (
            <h4 className='grid-element-title' style={_style.title}>{title}</h4>
        );
    }

    renderSubtitle() {
        const {subtitle} = this.props;

        if (!subtitle) return null;

        return (
            <h4 className='grid-element-subtitle' style={_style.subtitle}>{subtitle}</h4>
        );
    }

    renderBottomText() {
        const {bottom, bottomStyle} = this.props;

        if (!bottom) return null;

        return (
            <h4 className='grid-element-bottom' style={{..._style.bottom, ...bottomStyle}}>{bottom}</h4>
        );
    }

    render() {
        const {id, className, href, children, style} = this.props;

        return (
            <Link
                id={id || null}
                className={`grid-element ${className || ''}`}
                to={href || '#'}
                style={{..._style.gridElement, ...style}}
            >
                {this.renderBackgroundImage()}
                {this.renderOverlay()}
                {this.renderTitle()}
                {this.renderSubtitle()}

                {children}

                {this.renderBottomText()}
            </Link>
        );
    }
}

const _style = {
    gridElement: {
        // minWidth: 140,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bbb',
        borderRadius: 8,
        // padding: '.35rem',
        textDecoration: 'none',
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 8,
        zIndex: -1,
        transition: '.15s',
        WebkitTransition: '.15s',
    },
    title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: '1.1rem',
        margin: 0,
        padding: '.5rem',
        color: '#000',
        textShadow: '0 0 12px #fff, 0 0 12px #fff, 0 0 12px #fff',
    },
    subtitle: {
        fontSize: '1rem',
        margin: '0',
        color: '#777',
    },
    backgroundImg: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: 4,
        zIndex: -1,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        opacity: .8,
    },
    bottom: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        fontSize: '1rem',
        textAlign: 'center',
        margin: 0,
        padding: '.25rem 0',
        paddingBottom: 'calc(.25rem - 2.5px)',
    },
};