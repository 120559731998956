import React, {Component} from 'react';

import {GridElement} from "../base_components";

import colors from '../../values/colors';
import {getSensorStatus} from "../../utils/sensor_utils";
import {isObject} from "../../utils/utils";
import {SensorStatus} from "../../values/statuses";
import urls from '../../values/urls';
import {SENSOR_CONFIGURATION_THRESHOLD} from "../../values/config";

class ConifgSensor extends Component {
    constructor(props) {
        super(props);

        if (!isObject(props.sensor)) throw new TypeError('"sensor" must be an object and passed as prop');

        this.alarm_timeout = null;

        this.state = {
            mounted: false,
            alarm: false,
            status_verbose: ''
        };
    }

    customSetState(state) {
        if (this.state.mounted) {
            this.setState(state);
        }
    }

    componentDidMount() {
        this.setState({mounted: true});

        // calculate sensor status every second
        this.interval = setInterval(() => {
            this.customSetState({status_verbose: this.getStatusVerbose()});
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);

        this.setState({mounted: false});
    }

    getId() {
        const {sensor} = this.props;
        return `sensor-preview-${sensor.id}`;
    }

    getStatusVerbose() {
        if (this.state.alarm) return SensorStatus.ALARM;

        const {sensor} = this.props;
        const status = getSensorStatus(sensor);

        // calculate again sensor status in 5 minutes (5 minutes - time_diff)
        if (status === SensorStatus.ALARM) {
            clearTimeout(this.alarm_timeout);
            this.alarm_timeout = setTimeout(() => {
                this.forceUpdate();
            }, (1000 * 60 * 5) - Date.now() + (new Date(sensor.alarm_status.insert_date)).getTime());
        }

        return status;
    }

    getClassName() {
        const {status_verbose} = this.state;

        let class_name = '';

        if (status_verbose === SensorStatus.ALARM) {
            class_name = 'blink';
        }

        return class_name;
    }

    getBackgroundUrl() {
        const {sensor} = this.props;

        if (!isObject(sensor)) return null;

        const last_alarm_date = sensor.alarm_status.insert_date || null;
        const last_periodic_date = sensor.periodic_status.insert_date || null;

        let prop;
        if (!!last_alarm_date && !!last_periodic_date) {
            // choose one of them by date
            const date_alarm = (new Date(last_alarm_date)).getTime();
            const date_periodic = (new Date(last_periodic_date)).getTime();

            prop = date_alarm <= date_periodic
                ? 'alarm_status'
                : 'periodic_status';
        } else {
            prop = last_alarm_date
                ? 'alarm_status'
                : last_periodic_date
                    ? 'periodic_status'
                    : null;
        }

        // neither alarm nor periodic status are set
        if (!prop || !sensor[prop].image) return null;

        return sensor[prop].image.url;
    }

    renderBottom() {
        const {status_verbose} = this.state;

        return (
            <div className='sensor-preview-bottom' style={_style.bottomContainer}>
                <span>{status_verbose}</span>
            </div>
        );
    }


    render() {
        const {sensor, className, showOneSensorPerRow} = this.props;
        const {status_verbose} = this.state;
        // const status_verbose = SensorStatus.ALARM;

        // log('Rendering sensor preview. Sensor: ', sensor);

        const showOneSensorPerRowClass = showOneSensorPerRow
            ? 'one-per-row'
            : '';

        const disabled = (!sensor.new_generation || sensor.software_version < SENSOR_CONFIGURATION_THRESHOLD) ? 'disable' : '';

        return (
            <GridElement
                id={this.getId()}
                className={`config-element ${showOneSensorPerRowClass} ${disabled} ${className || ''} ${this.getClassName()}`}
                title={sensor.name}
                //bottom={this.renderBottom()}
                bottomStyle={{..._style.bottom.default, ..._style.bottom[status_verbose]}}
                href={`${urls.CONFIGURATION_BASE}/sensors/${sensor.id}`}
                //backgroundUrl={this.getBackgroundUrl()}
                overlay={'rgba(255, 255, 255, .2)'}
                style={{..._style.default, ..._style[status_verbose]}}
            >
            </GridElement>
        );
    }
}

const _style = {
    default: {
        color: '#fff',
        borderWidth: '2.5px',
        // backgroundColor: '#ffffff20',
        borderColor: colors.disabled,
    },
    [SensorStatus.ONLINE]: {
        borderColor: colors.success,
        // backgroundImage: `linear-gradient(to bottom, ${colors.success}95, ${colors.successLight}, ${colors.successTransparent})`,
    },
    [SensorStatus.OFFLINE]: {
        borderColor: colors.disabled,
        // backgroundImage: `linear-gradient(to bottom, ${colors.disabled}95, ${colors.disabledLight}, ${colors.disabledTransparent})`,
    },
    [SensorStatus.SLEEP]: {
        borderColor: colors.primaryLight,
        // backgroundImage: `linear-gradient(to bottom, ${colors.secondary}95, ${colors.secondaryLight}, ${colors.secondaryTransparent})`,
    },
    [SensorStatus.ALARM]: {
        borderColor: colors.error,
    },
    [SensorStatus.DISABLED]: {
        borderColor: colors.disabledLight,
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 .25rem',
        maxHeight: 19,
        fontSize: '.9rem',
    },
    bottom: {
        default: {
            color: '#fff',
            backgroundColor: colors.disabled,
        },
        [SensorStatus.ONLINE]: {
            backgroundColor: colors.success,
        },
        [SensorStatus.OFFLINE]: {
            backgroundColor: colors.disabled,
        },
        [SensorStatus.SLEEP]: {
            backgroundColor: colors.primaryLight,
        },
        [SensorStatus.ALARM]: {
            backgroundColor: colors.error,
        },
        [SensorStatus.DISABLED]: {
            backgroundColor: colors.disabledLight,
            color: colors.error,
            textTransform: 'uppercase',
        },
    },
    iconsContainer: {
        position: 'absolute',
        right: 4,
        bottom: 28,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: 'calc(16px + .5rem)',
        // boxShadow: '0 0 10px 5px rgba(255, 255, 255, .5)',
        backgroundColor: 'rgba(255, 255, 255, .5)',
        borderRadius: 'calc(8px + .25rem)',
    },
    icon: {
        width: 16,
        margin: '0 .25rem',
        // filter: 'drop-shadow(0px 0px 4px #fff) drop-shadow(0px 0px 4px #fff)',
    },
    iconBattery: {
        transform: 'rotate(-90deg)',
        width: 32,
        margin: 0,
    },
    blink: {
        WebkitAnimation: 'flash linear 1s infinite',
        animation: 'flash linear 1s infinite',
    },
};

export default ConifgSensor;