import React, {Component} from 'react';
import {connect} from "react-redux";
import {translate} from 'react-translate';

import {Loader, Modal} from "../../base_components";

import {deleteNotificationRule, saveNewNotificationsRule, updateNotificationsRules} from "./actions";

import {getTimeFilterShortDescription} from "../../../utils/settings_utils";
import {areEqualObjects} from "../../../utils/utils";

class EditRuleModal extends Component {
    constructor(props) {
        super(props);

        this.modal_id = 'edit_rule_modal';
        this.confirm_delete_modal_id = 'confirm_delete_modal_' + this.getModalId();

        this.state = {
            show: props.show,
            loading: false,
            opacity: 1,
            show_confirm_delete_modal: false,

            // rule props
            name: '',
            active: true,
            webapp: true,
            sensors: [],
            time_filters: [],
            events: [],
            handlers: [],
        };
    }

    componentDidUpdate(prevProps) {
        let state_obj = {};

        const {rule, show} = this.props;
        if (!areEqualObjects(prevProps.rule, rule)) state_obj = {
            ...state_obj,
            ...rule,
        };

        if (prevProps.show !== show) state_obj = {
            ...state_obj,
            show,
        };

        if (Object.keys(state_obj).length) this.setState(state_obj);
    }

    getRuleByState() {
        const {...rule} = this.state;
        return rule;
    }

    getModalId() {
        return this.props.idModal || this.modal_id;
    }

    isRuleSavable() {
        const rule = this.getRuleByState();
        const {t} = this.props;
        let rule_error_message = '';
        let check = true;
        const appendErrorText = text => {
            if (rule_error_message || rule_error_message.length) rule_error_message += '\n';
            rule_error_message += text;
        };

        if (!rule.name || !rule.name.length) {
            check = false;
            appendErrorText(t('add_valid_name'));
        }

        const array_props_not_empty = [
            {
                prop: 'sensors',
                err_msg: t('sensors_err_msg'),
            },
            {
                prop: 'time_filters',
                err_msg: t('time_filters_err_msg'),
            },
            {
                prop: 'events',
                err_msg: t('events_err_msg'),
            }
        ];
        for (let i = 0; i < array_props_not_empty.length; i++) {
            const {prop, err_msg} = array_props_not_empty[i];

            if (!rule[prop].length) {
                check = false;
                appendErrorText(err_msg);
            }
        }

        // check end
        return {
            savable: check,
            error: rule_error_message,
        };
    }

    dismissModal() {
        const {rule} = this.props;
        this.setState({
            ...rule,
            loading: false,
            show: false,
        });
    }

    onCancel() {
        const {onCancel} = this.props;
        if (onCancel) onCancel();

        this.dismissModal();
    }

    onSave() {
        this.setState({loading: true});

        const {onSave, newRule} = this.props;
        if (onSave) onSave();

        const rule = this.getRuleByState();
        const callback = () => this.dismissModal();

        if (newRule) this.props.saveNewNotificationsRule(rule, callback);
        else this.props.updateNotificationsRules([rule], callback);
    }

    renderSaveButton() {
        const {savable, error} = this.isRuleSavable();
        const {t} = this.props;

        const button = (
            <button
                className='btn btn-primary'
                disabled={!savable}
                onClick={() => this.onSave()}>
                {t('save_btn')}
            </button>
        );

        return !savable
            ? (
                <span className="d-inline-block" tabIndex="0" data-toggle="tooltip"
                      title={error}>
                    {button}
                </span>
            )
            : button;
    }

    onTimeFilterSelectChange(event) {
        if (!event) return null;

        const {options} = event.target;
        let list = [];
        for (let i = 0; i < options.length; i++) {
            const {selected, value} = options[i];
            if (selected) list.push(parseInt(value));
        }

        this.setState({time_filters: list});
    }

    renderTimeFiltersInput() {
        const {timeFilters, t} = this.props;
        const {time_filters} = this.state;

        if (!timeFilters || !time_filters) return null;

        return (
            <div className="form-group">
                <label htmlFor="mod-rule-time-filters">{t('time_filters')}</label>
                <select multiple className="form-control" id="mod-rule-time-filters" value={time_filters}
                        onChange={e => this.onTimeFilterSelectChange(e)}>
                    {
                        (timeFilters.results || []).map(tf =>
                            <option key={tf.id} value={tf.id}>
                                {tf.name} ({getTimeFilterShortDescription(tf)})
                            </option>
                        )
                    }
                </select>
            </div>
        );
    }

    onSensorSelectChange(event) {
        if (!event) return null;

        const {options} = event.target;
        let list = [];
        for (let i = 0; i < options.length; i++) {
            const {selected, value} = options[i];
            if (selected) list.push(parseInt(value));
        }

        this.setState({sensors: list});
    }

    renderSensorsInput() {
        const {plants, t} = this.props;
        const {sensors} = this.state;

        if (!plants || !sensors) return null;

        return (
            <div className="form-group">
                <label htmlFor="mod-rule-time-filters">{t('sensors')}</label>
                <select multiple className="form-control" id="mod-rule-time-filters" value={sensors}
                        onChange={e => this.onSensorSelectChange(e)}>
                    {
                        [].concat(...(plants.results || []).map(p =>
                            (p.sensors || []).map(s =>
                                <option key={s.id} value={s.id}>
                                    {s.name} ({p.name})
                                </option>)
                        ))
                    }
                </select>
            </div>
        );
    }

    onEventSelectChange(event) {
        if (!event) return null;

        const {options} = event.target;
        let list = [];
        for (let i = 0; i < options.length; i++) {
            const {selected, value} = options[i];
            if (selected) list.push(value);
        }

        this.setState({events: list});
    }

    renderEventsInput() {
        const {eventList, t} = this.props;
        const {events} = this.state;

        if (!eventList || !events) return null;

        return (
            <div className='form-group'>
                <label htmlFor="mod-rule-events">{t('events')}</label>
                <select multiple className="form-control" id="mod-rule-events" value={events}
                        onChange={e => this.onEventSelectChange(e)}>
                    {
                        (eventList.results || []).map((e, i) =>
                            <option key={i} value={e.name}>
                                {e.name} ({e.description})
                            </option>
                        )
                    }
                </select>
            </div>
        );
    }

    deleteConfirmModalShow() {
        this.setState({show_confirm_delete_modal: true});
    }

    deleteConfirmModalDismiss() {
        this.setState({
            show_confirm_delete_modal: false,
            loading: false,
        });
    }

    onDeleteConfirm() {
        this.setState({loading: true});

        const {id} = this.props.rule;

        this.props.deleteNotificationRule(id, () => {
            this.deleteConfirmModalDismiss();
            this.setState({show: false});
        });
    }

    renderConfirmDeleteModal() {
        if (!this.state.id || this.props.newRule) return null;

        const {t, rule} = this.props;
        const {confirm_delete_modal_id, state} = this;
        const {show_confirm_delete_modal} = state;

        return (
            <Modal id={confirm_delete_modal_id} show={show_confirm_delete_modal}
                   onShow={() => this.setState({opacity: 0})} onDismiss={() => this.setState({opacity: 1})}>
                <div className='modal-body'>
                    <p>{t('delete_rule_question', {name: rule.name})}</p>
                </div>
                <div className='modal-footer'>
                    <button className='btn btn-secondary'
                            onClick={() => this.deleteConfirmModalDismiss()}>{t('cancel_btn')}
                    </button>
                    <button className='btn btn-danger' onClick={() => this.onDeleteConfirm()}>{t('delete_btn')}</button>
                </div>
            </Modal>
        );
    }

    renderDeleteButton() {
        const {newRule, t} = this.props;

        return !newRule
            ? <button className='btn btn-danger' onClick={() => this.deleteConfirmModalShow()}>{t('delete_btn')}</button>
            : null;
    }

    render() {
        if (!this.props.rule) return null;

        // console.log(this.state);

        const id = this.getModalId();

        const {newRule, rule, onShow, onShown, onHide, onDismiss, t} = this.props;
        const {name, active, webapp, loading, opacity, show} = this.state;

        const modal_title = newRule
            ? t('title_new')
            : t('title_edit', {name: rule.name});

        return (
            <div>
                <Modal id={id} show={show} style={{opacity}} onShow={onShow} onShown={onShown} onHide={onHide}
                       onDismiss={onDismiss}>

                    <div className='modal-body'>
                        <p className='modal-title'>{modal_title}</p>
                        <form>
                            <div className='form-group'>
                                <label htmlFor="mod-rule-name">{t('name')}</label>
                                <input type='text' id='mod-rule-name' className='form-control'
                                       onChange={e => this.setState({name: e.target.value})}
                                       value={name}/>
                            </div>
                            <div className='form-group'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={active} value={active}
                                           onChange={() => this.setState({active: !active})}
                                           id="mod-rule-active"/>
                                    <label className="form-check-label" htmlFor="mod-rule-active">
                                        {t('active')}
                                    </label>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={webapp} value={webapp}
                                           onChange={() => this.setState({webapp: !webapp})}
                                           id="mod-rule-webapp"/>
                                    <label className="form-check-label" htmlFor="mod-rule-webapp">
                                        {t('webapp')}
                                    </label>
                                </div>
                            </div>
                            {this.renderTimeFiltersInput()}
                            {this.renderSensorsInput()}
                            {this.renderEventsInput()}
                        </form>
                    </div>
                    <div className='modal-footer'>
                        <button className='btn btn-secondary' onClick={() => this.onCancel()}>{t('cancel_btn')}</button>

                        {this.renderDeleteButton()}
                        {this.renderSaveButton()}
                    </div>
                </Modal>

                {this.renderConfirmDeleteModal()}

                <Loader loading={loading}/>
            </div>
        );
    }
}

export default connect(null, {
    updateNotificationsRules,
    saveNewNotificationsRule,
    deleteNotificationRule,
})(translate('EditRuleModal')(EditRuleModal));