import React, {Component} from 'react';
import {connect} from "react-redux";
import {translate} from 'react-translate';

import {hideCookieBanner} from "./actions";

import colors from "../../../values/colors";
import {log} from "../../../utils/utils";
import urls from "../../../values/urls";

class CookieBanner extends Component {
    constructor(props) {
        super(props);

        this.html_id = 'cookie-banner';
    }

    componentDidMount() {
        log('Cookie banner mounted');
        const {show} = this.props;

        // hide whatsUp chat if cooke banner visible
        if (show) {
            let whats_up = document.getElementById("whats-up-icon");
            if (whats_up) { 
                whats_up.style.display = "none" ;

            // for downloading script delay
            } else {
                setTimeout(() => { 
                    let whats_up = document.getElementById("whats-up-icon");
                    if (whats_up) { 
                        whats_up.style.display = "none";
                    } 
                }, 5000);
            }
        }
    }

    hideBanner() {
        const selector = `#${this.html_id}`;
        const bottom = (-1) * ($(selector).height() || 0);  // eslint-disable-line no-undef

        // animation
        $(selector).animate({bottom}, {  // eslint-disable-line no-undef
            duration: 280,
            complete: () => this.props.hideCookieBanner(),
        });
    }

    getContainerStyle() {
        const {show} = this.props;
        const display = show ? 'block' : 'none';

        return {
            ..._style.container,
            display,
        };
    }

    render() {
        const {t} = this.props;
        return (
            <div id={this.html_id} style={this.getContainerStyle()}>
                <div className='wrapper container' style={_style.wrapper}>
                    <p>{t("text")}</p>

                    <div>
                        <button className='btn btn-info' onClick={() => this.hideBanner()}>{t("confirm_text")}</button>
                        <a className='btn btn-dark' onClick={() => this.hideBanner()}
                           href={urls.PRIVACY_INFO} target='_blank' rel="noreferrer">{t("details_text")}</a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({cookieBanner}) => {
    return {
        show: cookieBanner.show,
    };
};

export default connect(mapStateToProps, {hideCookieBanner})(translate("CookieBanner")(CookieBanner));

const _style = {
    container: {
        display: 'block',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: colors.cream,
        borderTop: '1px solid',
        borderColor: colors.creamDark,
    },
    wrapper: {
        paddingTop: '.75rem',
        paddingBottom: '.75rem',
    },
};