import React from 'react';
import SensorPreviewIcon from './sensor_preview_icon';

const SemaphoreIcon = props => {
    const {disabled, web, style} = props;

    return (
        <SensorPreviewIcon name={'light'} disabled={disabled} web={web} style={style}/>
    );
};


export default SemaphoreIcon;