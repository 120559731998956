import React, {Component} from 'react';
import {connect} from 'react-redux';

import SensorPreview from "./sensor_preview";
import {Grid} from "../base_components";
import {naturalCompare} from "../../utils/utils";
import {getSensorInitialCheckedObject} from "../../utils/commands_utils";

import {types as CommandTypes} from "../../values/commands";
import {SENSOR_MODE} from "../../values/action_types";
import Command from "./command";

class SensorList extends Component {

    get_initial_state(sensor) {
        const {mode} = this.props;
        let checked = false;
        let initialCheckedObj = getSensorInitialCheckedObject(sensor);

        if (mode == SENSOR_MODE) {
            checked = initialCheckedObj[CommandTypes.LIGHT];
        } else {
            checked = initialCheckedObj[CommandTypes.SIREN] && initialCheckedObj[CommandTypes.LIGHT];
        }
        return checked;
    }

    get_grid_by_mode() {
        const {track, showOneSensorPerRow, mode} = this.props;
        if (!!track.sensors && Array.isArray(track.sensors) && track.sensors.length < 1) {
            return <div className='no-sensors' style={_style.no_sensors}>There are no sensors</div>;  //add translations
        }

        track.sensors.sort((a, b) => naturalCompare(a, b, 'name'));

        let sensors = null;

        if (mode != SENSOR_MODE) {

            sensors = track.sensors.map(sensor => <SensorPreview
                            sensor={sensor}
                            showOneSensorPerRow={showOneSensorPerRow}
                            key={sensor.id}
                        />
                     );

        } else {
            const status_obj = this.props.status_obj || {};
            // remove sensors if status_only is true
            let filtered_sensors = track.sensors.filter(sensor => !status_obj[sensor.id]);

            if (filtered_sensors.length < 1) {
                return <div className='no-sensors' style={_style.no_sensors}>There are no sensors</div>;  //add translations
            }

            sensors = filtered_sensors.map( sensor =>
                <div className="sensor-switch-btn" key={sensor.id}>
                    <h1 className='page-title sensor-swith-title'>{sensor.name}</h1>
                    <Command
                        switchButton
                        idSensor={sensor.id}
                        idPlant={track.id}
                        type={CommandTypes.SENSOR_SWITCH}
                        key={sensor.id}
                        waitForNewStates
                        sensor={sensor}
                        initialChecked={this.get_initial_state(sensor)}
                    />
                </div>
            );
        }

        return sensors;
    }

    render() {
        return (
            <Grid className='dashboard-track-sensors'>
                {this.get_grid_by_mode()}
            </Grid>
        );
    }
}

export default connect()(SensorList);

const _style = {
    no_sensors: {
        textAlign: 'center',
        color: 'rgb(255, 255, 255)',
        paddingBottom: '3rem',
        flex: 'auto',
        fontSize: '2rem',
    },
};