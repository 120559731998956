import {isObject} from "./utils";
import {NotificationEventsApi} from '../values/notification_events';
import Icons from '../values/icon_names';
import urls from '../values/urls';

const check_list = list => list && Array.isArray(list);
// const millis_in_day = 24 * 60 * 60 * 1000;

export const getUnreadNotificationsCount = list => {
    if (check_list(list)) {
        let count = 0;
        for (let i = 0; i < list.length; i++) count += !list[i].read_on ? 1 : 0;

        return count;
    }

    return 0;
};

//export const getNewNotificationsCount = (list, elapsed_time /* millis */) => {
//    if (check_list(list)) {
//        const now = moment().utc().valueOf();
//        let count = 0;
//        for (let i = 0; i < list.length; i++) {
//            const date = moment(list[i].created_on).valueOf();
//            count += now - date <= (elapsed_time || millis_in_day) ? 1 : 0;
//        }
//
//        return count;
//    }
//
//    return 0;
//};

//export const appendNewNotificationsToState = (old_state = {}, new_list = [], list_prop_name, additional_data) => {
//    if (!list_prop_name) return old_state;
//
//    let old_list = old_state[list_prop_name];
//    for (let i = 0; i < new_list.length; i++) {
//        if (old_list.findIndex(n => n.id === new_list[i].id) < 0) old_list.push(new_list[i]);
//    }
//
//    return {
//        ...old_state,
//        ...additional_data,
//        [list_prop_name]: old_list,
//    };
//};

export const getHrefByNotification = notification => {
    if (!isObject(notification)) throw new TypeError('\'notification\' must be an object');

    let href;
    const {event} = notification;

    switch (event) {
        case NotificationEventsApi.ALARM:
            href = `${urls.NOTIFICATIONS_BASE}/detail/${notification.id}/`;
            break;
        case NotificationEventsApi.SOFT_ALERT:
        case NotificationEventsApi.HARD_ALERT:
            href = `${urls.DASHBOARD_BASE}/sensors/${notification.sensor}/`;
            break;
        default:
            href = '#';
            break;
    }

    return href;
};

export const getIconNameByNotificationEventApi = event_api => {
    // if (typeof event !== 'string') throw new TypeError('\"event\" must be a string');

    let event;

    switch (event_api) {
        case NotificationEventsApi.SOFT_ALERT:
        case NotificationEventsApi.HARD_ALERT:
            event = Icons.ALERT;
            break;
        case NotificationEventsApi.ALARM:
            event = Icons.ALARM;
            break;
        default:
            event = event_api;
            break;
    }

    return event;
};