import React, {Component} from 'react';
import {Player} from "video-react";
import {Modal} from "../base_components";

export default class VideoPlayerModal extends Component {
    constructor(props) {
        super(props);

        this.player_ref = React.createRef();
        this.id_modal = 'video__player__modal';
    }

    render() {
        const {src, poster, playsInline, idModal, onHide, ...rest} = this.props;

        // if (!src) return null;

        const ref = this.player_ref;
        // console.log(this.refs);

        return (
            <Modal id={idModal || this.id_modal} {...rest}
                   onHide={() => {
                       if (onHide) onHide();
                       if (ref.current) ref.current.pause();
                   }}>
                <Player ref={ref} playsInline={playsInline} poster={poster} src={src}/>
            </Modal>
        );
    }
}
