import React from 'react';
import ReactModal from '../modules/base_components/components/react_modal';

export const renderMarks = (min, max, step) => {
    let marks = {};

    // for mobile wiew to fit and show not every value
    let mobile_step = 0;
    if (window.innerWidth < 575) {
        mobile_step = Math.floor( ((max - min) / step) / 10 );
    }

    for (let i=min, index=mobile_step; i<=max; i=i+step) {
        i = Math.round(i * 100) / 100;
        if (index == 0 || i==min) {
            marks[i] = i;
            if (mobile_step != 0) {
                index = mobile_step;
            }
        } else {
            marks[i] = "";
            index = index - 1;
        }
    }
    return marks;
};

export const renderPopup = (config) => {
    let name = "modalErrorMsg";

    let popup_text = config.error ? config.error.message || config.error.response.data.message || config.error.response.statusText : config.response
            ? config.response.data.message
            : null;

    if ( !popup_text ) {
           popup_text = (config == 'uploaded') ? "Configuration have been saved succesfully" : null;
           name = "modalSuccessMsg";
    }
    return popup_text
        ? <ReactModal text={popup_text} show={true} name={name}/>
        : null;
};