import React from 'react';

import Modal from './modal';

const Popup = props => {
    const {idModal, text, show, onHide, children} = props;

    return (
        <Modal id={idModal} show={show} onHide={onHide}>
            {
                children ||
                <p className='error-popup-text'>{text}</p>
            }
        </Modal>
    );
};

export default Popup;