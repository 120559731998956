import React from 'react';

const Postlist = (props) => {
    const {children} = props;

    return (
        <div className='post-list' style={_style.list}>
            {children}
        </div>
    );
};

const _style = {
    list: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    }
};

export default Postlist;