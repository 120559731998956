import axios from "axios";

import {
    SENSOR_CONFIG_FETCH_ERROR,
    SENSOR_CONFIG_FETCH_SUCCESS,
    SENSOR_CONFIG_LOADING,
    SENSOR_CONFIG_SAVE_SUCCESS
} from "../../values/action_types";
import {reqOptions} from "../../utils/auth";
import {buildActionErrorObject, buildActionObject, getApiPath, handleError} from "../../utils/actions_utils";
import {CONFIGURATION_APP} from "../../values/api_apps";


export const fetchSensorConfig = (sensor_id , endpoint)=> dispatch => {
    if (!sensor_id) dispatch(buildActionErrorObject(SENSOR_CONFIG_FETCH_ERROR, {message: 'sensor_id must be specified'}));

    const url = getApiPath(CONFIGURATION_APP, endpoint);

    dispatch(buildActionObject(SENSOR_CONFIG_LOADING));

    return axios.get(url, reqOptions)
        .then(res => dispatch(buildActionObject(SENSOR_CONFIG_FETCH_SUCCESS, {...res.data, id: sensor_id})))
        .catch(error => handleError(error, dispatch, SENSOR_CONFIG_FETCH_ERROR));
};

export const saveSensorConfig = (sensor_id , endpoint, data)=> dispatch => {
    if (!sensor_id) dispatch(buildActionErrorObject(SENSOR_CONFIG_FETCH_ERROR, {message: 'sensor_id must be specified'}));

    const url = getApiPath(CONFIGURATION_APP, endpoint);

    dispatch(buildActionObject(SENSOR_CONFIG_LOADING));

    return axios.post(url, data, reqOptions)
        .then(res => dispatch(buildActionObject(SENSOR_CONFIG_SAVE_SUCCESS, {...res.data, id: sensor_id})))
        .catch(error => handleError(error, dispatch, SENSOR_CONFIG_FETCH_ERROR));
};