import React from "react";
import {SyncLoader} from "react-spinners";

const Loader = props => {
    return (
        <SyncLoader
            className='loading'
            color={'#fff'}
            loading={props.loading}
        />
    );
};

export default Loader;