const PORT = !!process.env.PORT
    ? `:${process.env.PORT}`
    : '';

const API_URL = process.env.API_URL || `${window.location.hostname}`;
const API_PROTOCOL = process.env.API_PROTOCOL;
const WS_PROTOCOL = process.env.WS_PROTOCOL;
const API_BASE = `${API_PROTOCOL}://${API_URL}${PORT}`;
const WS_BASE = `${WS_PROTOCOL}://${API_URL}${PORT}`;

console.log('Api base:', API_BASE);
console.log('WS base:', WS_BASE);

export default {
    API_BASE,
    DASHBOARD_BASE: '/training',
    SENSORS_BASE: '/race',
    ALARMS_BASE: '/alarms',
    IMAGES_BASE: '/images',
    NOTIFICATIONS_BASE: '/notifications',
    SETTINGS_BASE: '/settings',
    CONFIGURATION_BASE: '/configuration',
    LOGIN: '/login',
    DEV_TEST: '/dev-test',
    NOT_FOUND_DEFAULT: '/not-found',
    WS_BASE,
    WS_PERIODIC_STATUSES_BASE: '/periodic-statuses/',
    PRIVACY_INFO: 'https://www.porzioepartners.com/informativa-sulla-privacy',
};
