import urls from '../values/urls';
import {error as ErrorCodes} from "../values/response_codes";
import {AUTH_SET_NEXT, LOGIN_ERROR, NAVIGATION_SET_REDIRECT} from "../values/action_types";
import {isFunction} from "./utils";

const API_VERSION = 'v1';

export const getApiPath = (app_name, url) => `${urls.API_BASE}/${app_name}/api/${API_VERSION}${url}`;

export const buildActionObject = (type, payload) => {
    return {type, payload};
};

export const buildActionErrorObject = (type, error) => {
    return {type, error};
};

export const handleError = (error, dispatch, defaultErrorType) => {
    if (!error) throw new EvalError('\'error\' does not exist. Cannot handle null or undefined error');
    if (!isFunction(dispatch)) throw new TypeError('\'dispatch\' must be a function');
    if (!defaultErrorType) throw new EvalError('Default error type was not provided');

    const defaultErrorAction = buildActionErrorObject(defaultErrorType, error);

    const {response} = error;
    if (!response) {
        // handle network error
        dispatch(defaultErrorAction);
        return null;
    }

    switch (response.status) {
        case ErrorCodes._401_UNAUTHORIZED:
        case ErrorCodes._403_FORBIDDEN:
            // set current path as next and dispatch the login error
            dispatch(buildActionObject(AUTH_SET_NEXT, {
                next: window.location.pathname + window.location.search,
            }));
            dispatch(buildActionErrorObject(LOGIN_ERROR, error));
            break;
        case ErrorCodes._404_NOT_FOUND:
            dispatch(buildActionObject(NAVIGATION_SET_REDIRECT, urls.NOT_FOUND_DEFAULT));
            break;
        default:
            dispatch(defaultErrorAction);
            break;
    }
};