import React from 'react';
import {Icon} from "../base_components";

import colors from '../../values/colors';
import {BatteryStatusApi} from "../../values/statuses";
import {getBatteryStatusByApi} from "../../utils/sensor_utils";

const getBatteryVoltage = voltage => voltage || voltage === 0 ? `${Math.round(voltage * 10) / 10}V` : '';
const getContainerStyle = iconWidth => ({
    width: iconWidth,
    height: iconWidth,
    position: 'relative',
});
const getIconStyle = additional_style => ({
    ..._style.icon,
    ...additional_style,
});
const getVoltageStyle = (iconWidth, is_battery_status_full) => {
    const base_icon_width = 32;
    const base_voltage_font_size = 8;
    const rapp = base_voltage_font_size / base_icon_width;

    return {
        ..._style.voltage_label,
        fontSize: iconWidth * rapp,
        color: is_battery_status_full ? colors.cream : colors.disabledDark,
    };
};

const BatteryIcon = props => {
    const {batteryStatus, voltage = '15.5V', style, containerStyle, className} = props;

    if (!batteryStatus || batteryStatus === BatteryStatusApi.UNKNOWN) return null;

    let class_name = '';
    if (batteryStatus === BatteryStatusApi.LOW || batteryStatus === BatteryStatusApi.EMPTY) {
        class_name = 'blink';
    }

    const icon_style = getIconStyle(style);
    const voltage_style = getVoltageStyle(icon_style.width, batteryStatus === BatteryStatusApi.FULL);
    const container_style = getContainerStyle(icon_style.width);

    return (
        <div style={{...container_style, ...containerStyle}} className={`${class_name} ${className}`}>
            <Icon
                name={`battery_${getBatteryStatusByApi(batteryStatus)}`}
                style={icon_style}
                colored
                fill
            />
            <span style={voltage_style}>{getBatteryVoltage(voltage)}</span>
        </div>
    );
};

const _style = {
    icon: {
        width: 32,
        transform: 'rotate(-90deg)',
        margin: 0,
        position: 'absolute',
        top: 0,
        left: 0,
    },
    voltage_label: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 1px)',
        transform: 'translate(-50%, -50%)',
    },
};

export default BatteryIcon;