import urls from './urls';
import icons from './icon_names';
import lang from '../i18n';
import {IS_ROUTER_ENV} from "../utils/utils";

export const MenuStringItems = {
    TRAINING: lang.Menu.training,
    RACE: lang.Menu.race,
    ALARMS: lang.Menu.alarms,
    IMAGES: lang.Menu.images,
    NOTIFICATIONS: lang.Menu.notifications,
    SETTINGS: lang.Menu.settings,
    CONFIGURATION: lang.Menu.configuration,
    DEV_TEST: 'Test',
};

const create_menu_item = (text, href, icon) => ({text, href, icon});

export const MenuItems = [
    create_menu_item(MenuStringItems.TRAINING, urls.DASHBOARD_BASE, icons.ALARM),
    create_menu_item(MenuStringItems.RACE, urls.SENSORS_BASE, icons.ALARM),
    create_menu_item(MenuStringItems.ALARMS, urls.ALARMS_BASE, icons.ALARM),
    create_menu_item(MenuStringItems.IMAGES, urls.IMAGES_BASE, icons.ALARM),
];

if (process.env.NODE_ENV === 'development') {
    MenuItems.push(create_menu_item(MenuStringItems.DEV_TEST, urls.DEV_TEST));
}

if (!IS_ROUTER_ENV) {
    MenuItems.splice(3, 0,
        create_menu_item(MenuStringItems.NOTIFICATIONS, urls.NOTIFICATIONS_BASE, icons.ALARM),
        create_menu_item(MenuStringItems.SETTINGS, urls.SETTINGS_BASE, icons.ALARM),
    );
    MenuItems.splice(-1, 0,
        create_menu_item(MenuStringItems.CONFIGURATION, urls.CONFIGURATION_BASE, icons.ALARM)
    );
}