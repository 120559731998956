import React, {Component} from 'react';
import moment from "moment";
import {Carousel} from "react-responsive-carousel";

import VideoPlayerModal from "./video_player_modal";

export default class AlarmsCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidUpdate() {
        // console.log(this.state);
    }

    onClickItem(index) {
        if (isNaN(index) || index < 0) return null;

        const {hits} = this.props.alarms;
        if (!hits[index].video_x264) return null;

        const {id} = hits[index];
        this.setState({current_alarm_id: id});
    }

    renderCarousel() {
        const {alarms, selectedItem = 0, onChange = () => {}} = this.props;

        if (!alarms || !alarms.hits || !alarms.hits.length) return null;

        return (
            <Carousel
                onClickItem={i => this.onClickItem(i)}
                emulateTouch={true}
                showIndicators={false}
                selectedItem={selectedItem}
                onChange={onChange}
            >
                {
                    alarms.hits.map((h, i) =>
                        (
                            <div key={i}>
                                {
                                    h.image
                                        ? <img src={h.image.url} style={_style.carouselImage}
                                               alt='Immagine non disponibile'/>
                                        : <img alt='Immagine non disponibile'/>
                                }
                                <p className='legend pers-legend'>
                                    {moment(h.insert_date).format('DD/MM/YYYY - HH:mm')}
                                </p>
                            </div>
                        )
                    )
                }
            </Carousel>
        );
    }

    render() {
        const {alarms, style} = this.props;

        if (!alarms || alarms.error || !alarms.hits) return null;

        const {current_alarm_id} = this.state;
        const alarm = alarms.hits.find(h => h.id === current_alarm_id);

        return (
            <div style={style}>
                <VideoPlayerModal
                    show={!!current_alarm_id}
                    idModal={this.video_player_modal_id}
                    playsInline
                    poster={alarm && alarm.image ? alarm.image.url : ''}
                    src={alarm && alarm.video_x264 ? alarm.video_x264.url : ''}
                    onDismiss={() => this.setState({current_alarm_id: null})}
                />

                {this.renderCarousel()}
            </div>
        );
    }
}

const _style = {
    carouselImage: {
        height: 'auto',
    }
};