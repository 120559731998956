import axios from "axios";

import {buildActionErrorObject, buildActionObject, getApiPath, handleError} from "../../../utils/actions_utils";
import {
    RULE_CREATE_ERROR,
    RULE_CREATE_SUCCESS,
    RULE_DELETE_ERROR,
    RULE_DELETE_SUCCESS,
    RULES_UPDATE_ERROR,
    RULES_UPDATE_SUCCESS
} from "../../../values/action_types";
import {NOTIFICATION_APP} from "../../../values/api_apps";
import {reqOptions} from "../../../utils/auth";
import {isObject, runFunction} from "../../../utils/utils";

/**
 *
 * @param {Array} rules
 * @param {function} callback
 */
export const updateNotificationsRules = (rules, callback) => dispatch => {
    if (!rules || !Array.isArray(rules)) return buildActionErrorObject(RULES_UPDATE_ERROR, {message: 'Wrong params'});

    const reqs = rules.map(r => axios.put(
        getApiPath(NOTIFICATION_APP, `/rules/${r.id}/`), // url
        r, // data
        reqOptions // config
    ));

    return axios.all(reqs)
        .then(response => dispatch(buildActionObject(RULES_UPDATE_SUCCESS, response /* array */)))
        .catch(error => handleError(error, dispatch, RULES_UPDATE_ERROR))
        .finally(() => runFunction(callback));
};

export const deleteNotificationRule = (id_rule, callback) => dispatch => {
    if (!id_rule || isNaN(parseInt(id_rule))) return buildActionErrorObject(RULE_DELETE_ERROR, {message: 'Wrong params'});

    const url = getApiPath(NOTIFICATION_APP, `/rules/${id_rule}/`);

    return axios.delete(url, reqOptions)
        .then(() => dispatch(buildActionObject(RULE_DELETE_SUCCESS, {id_rule})))
        .catch(error => handleError(error, dispatch, RULE_DELETE_ERROR))
        .finally(() => runFunction(callback));
};

export const saveNewNotificationsRule = (rule, callback) => dispatch => {
    if (!rule || !isObject(rule)) return buildActionErrorObject(RULE_CREATE_ERROR);

    const url = getApiPath(NOTIFICATION_APP, '/rules/');

    return axios.post(url, rule, reqOptions)
        .then(({data}) => dispatch(buildActionObject(RULE_CREATE_SUCCESS, data)))
        .catch(error => handleError(error, dispatch, RULE_CREATE_ERROR))
        .finally(() => runFunction(callback));
};
